import Description from "components/description";
import View from "components/layout/view";
import { FunctionComponent } from "react";
import Section from "components/layout/section";
import Block from "components/layout/block";
import Title from "components/title";
import Navbar from "components/navigation/navbar";
import SectionWrapper from "components/layout/section-wrapper";
import Background from "components/layout/background";
import List from "components/list";
import { getStoreUrl } from "lib/store";
import { useTranslations } from "hooks/translations";
import { replaceTags } from "lib/string";

const HandleYourSubscriptionView: FunctionComponent = () => {
  const t = useTranslations();
  return (
    <View
      title={t("handle_subcription_title")}
      description={t("handle_subcription_description")}
      footer
      showLogoInFooter
      view="light"
    >
      <Navbar links />
      <SectionWrapper gray>
        <Section>
          <Block order={2}>
            <Background
              mobileImageUrl="home/WomanSunglassesHandleSubBg"
              tabletImageUrl="home/WomanSunglassesTabletBg"
              desktopImageUrl="home/WomanSunglassesHandleSubBg"
              align="right"
            />
          </Block>
          <Block order={1}>
            <Title>{t("handle_subcription_title")}</Title>
            <Description>{t("handle_subcription_p1")}</Description>
            <Description>{t("handle_subcription_p2")}</Description>
            <Title mode="h2">{t("handle_subcription_cancel_title")}</Title>
            <Description>{t("handle_subcription_cancel_p1")}</Description>
            <Description>{t("handle_subcription_cancel_p2")}</Description>
            <List
              type="number"
              items={[
                {
                  number: 1,
                  text: replaceTags(t("handle_subcription_cancel_item1"), {
                    LINK: getStoreUrl(),
                  }),
                },
                {
                  number: 2,
                  text: t("handle_subcription_cancel_item2"),
                },
                {
                  number: 3,
                  text: t("handle_subcription_cancel_item3"),
                },
              ]}
            />
            <Description>{t("handle_subcription_initial_text1")}</Description>
            <List
              type="normal"
              items={[
                {
                  text: t("handle_subcription_initial_item1"),
                },
                {
                  text: t("handle_subcription_initial_item2"),
                },
              ]}
            />
            <Description>{t("handle_subcription_initial_text2")}</Description>
            <List
              type="normal"
              items={[
                {
                  text: t("handle_subcription_initial_item3"),
                },
              ]}
            />
            <Description>{t("handle_subcription_refund")}</Description>
            <Title mode="h2">{t("handle_subcription_help_title")}</Title>
            <Description>{t("handle_subcription_help_text")}</Description>
          </Block>
        </Section>
      </SectionWrapper>
    </View>
  );
};

export default HandleYourSubscriptionView;
