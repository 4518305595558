import { updateGeo } from "actions/geo";
import { useBackendUrl } from "hooks/geo";
import { FunctionComponent, ReactNode, useEffect } from "react";

interface ConfigProviderProps {
  children: ReactNode;
}

const GeoProvider: FunctionComponent<ConfigProviderProps> = ({ children }) => {
  const backendUrl = useBackendUrl();
  

  useEffect(() => {
    updateGeo();
  }, []);

  if (!backendUrl) {
    return null;
  }

  return children;
};

export default GeoProvider;
