import HelmetWrapper from "components/helmet";
import { useRouteMatch } from "hooks/route";
import { RouteHandle } from "./definitions";

const CanonicalUrl = () => {
  const match = useRouteMatch();

  // no route match
  if (!match || !match.handle) {
    return null;
  }

  // no canonical for this url
  const { canonical } = match.handle as RouteHandle;
  if (!canonical) {
    return null;
  }

  return (
    <HelmetWrapper>
      <link rel="canonical" href={canonical} />
    </HelmetWrapper>
  );
};

export default CanonicalUrl;
