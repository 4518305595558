import { useTheme } from "hooks/theme";
import { FunctionComponent } from "react";
import { StyledTable } from "./table.styles";

interface TableProps {
  children: any;
}

const Table: FunctionComponent<TableProps> = ({ children }) => {
  const theme = useTheme();
  return (
    <StyledTable $theme={theme}>
      <tbody>{children}</tbody>
    </StyledTable>
  );
};

export default Table;
