import View from "components/layout/view";
import { FunctionComponent, useEffect } from "react";
import Navbar from "components/navigation/navbar";
import ContentContainer from "components/layout/content";
import { TagMap, getUserTags } from "lib/tag";
import { useQuestionnaireState } from "hooks/state";
import { replaceTags } from "lib/string";
import Title from "components/title";
import List from "components/list";
import { StyledListHeading } from "components/list.styles";
import Description from "components/description";
import { write } from "lib/storage";
import { SKIP_TO_INSTALL_KEY } from "models/user";
import BulletList from "components/questionnaire/bullet-list";
import PaymentContent from "../question/content/base/types/payment";
import AppRatingContent from "../question/content/base/types/app-rating";
import { StyledBadge } from "./view.styles";
import { useDiscount } from "hooks/products";
import { useTranslations } from "hooks/translations";

const QuestionnaireReactivateView: FunctionComponent = () => {
  const state = useQuestionnaireState();
  const discount = useDiscount();
  const t = useTranslations();

  useEffect(() => {
    // after payment, skip straight to install screen
    write<boolean>(SKIP_TO_INSTALL_KEY, true);
  }, []);

  // build a tagmap
  const tags: TagMap = {
    ...getUserTags(state),
  };

  const titleText = replaceTags(t("reactivate_title"), tags);

  // exceptionally parse months from the product
  const months = 6;

  const typeSubscription = replaceTags(t("reactivate_payment_type"), {
    MONTHS: months + 1,
  });

  return (
    <View title={titleText} description="" view="dark" cover>
      <Navbar transparent logoAsLink={false} />
      {discount && <StyledBadge filename="DiscountBadge" />}
      <ContentContainer>
        <Title>{titleText}</Title>
        <StyledListHeading>{t("reactivate_objectives")}</StyledListHeading>
        <List
          items={[
            {
              text: t("reactivate_objective"),
            },
          ]}
        />
        <Description>{t("reactivate_text")}</Description>
        <BulletList
          paragraphs={[
            {
              id: 1,
              label: t("reactivate_list_title_1"),
              text: t("reactivate_list_text_1"),
            },
            {
              id: 2,
              label: t("reactivate_list_title_2"),
              text: t("reactivate_list_text_2"),
            },
          ]}
          tags={tags}
        />
        <PaymentContent
          tags={tags}
          initial={false}
          onChange={() => {}}
          onSubmit={() => {}}
          handleScroll={() => {}}
          handleNavigation={async () => {}}
          content={{
            paymentOptions: {
              productSelector: "single-frame",
              noConfiguration: true,
              badge: discount ? "DiscountBadge2" : undefined,
            },
            paymentTexts: {
              description: t("reactivate_payment_description"),
              paymentHeader: t("reactivate_payment_header"),
              title: t("reactivate_payment_title"),
              typeSubscription,
              paymentFooter: t("reactivate_payment_footer"),
            },
            type: "payment",
          }}
        />
        <AppRatingContent
          tags={tags}
          initial={false}
          onChange={() => {}}
          onSubmit={() => {}}
          handleScroll={() => {}}
          handleNavigation={async () => {}}
          content={{
            appRatingStars: true,
            appRatingTexts: {
              def: t("reactivate_reviews"),
            },
            type: "appRating",
          }}
        />
      </ContentContainer>
    </View>
  );
};

export default QuestionnaireReactivateView;
