import { Locales, LocaleType } from "models/locale";
import { StyledLocale, StyledSwitcher } from "./locale-switch.styles";
import { useLocale } from "hooks/geo";
import { useRoute, useRouteParams } from "hooks/route";
import { useNavigate } from "react-router";

export const LocaleSwitch = () => {
  const locale = useLocale();
  const route = useRoute();
  const params = useRouteParams();
  const navigate = useNavigate();

  if (import.meta.env.VITE_PUBLIC_SWITCH_LOCALE !== "true") {
    return null;
  }

  const changeTo = (locale: LocaleType) => {
    if (route) {
      const { pathSets } = route;
      const set = pathSets[locale];
      if (set) {
        let { path } = set;
        for (const key in params) {
          path = path.replace(`:${key}`, params[key] || "");
        }

        navigate(`/${locale}/${path}`);
      }
    }
  };

  return (
    <StyledSwitcher>
      {Locales.map((loc) => {
        if (loc === locale) {
          return null;
        }

        return (
          <StyledLocale onClick={() => changeTo(loc)} key={loc}>
            {loc}
          </StyledLocale>
        );
      })}
    </StyledSwitcher>
  );
};

export default LocaleSwitch;
