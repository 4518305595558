import { v4 } from "uuid";
import { StateCreator } from "zustand";

export interface IdsSlice {
  deviceUuid: string;
  vid: string | undefined;
  cid: string | undefined;
}

export const createIdsSlice: StateCreator<IdsSlice, [], [], IdsSlice> = () => ({
  deviceUuid: v4(),
  vid: undefined,
  cid: undefined,
});
