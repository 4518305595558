import { GeoModel } from "models/geo";
import { CountryType } from "models/locale";

/** @deprecated */
export const fetchCountry = async () => {
  const response = await fetch("/geo.json");
  const json: GeoModel = await response.json();
  return json.country.toUpperCase() as CountryType;
};

export const fetchGeo = async () => {
  const response = await fetch("/geo.json");
  const json: GeoModel = await response.json();
  return json;
};
