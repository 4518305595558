import { CONFIG_STATE_KEY } from "models/config";
import { PAYMENT_RETRY_URL_KEY, PAYMENT_STATUS_KEY } from "models/payment";
import { PRODUCT_KEY } from "models/product";
import {
  QUESTIONNAIRE_KEY,
  STATE_KEY,
  TARGET_KEY,
  VERSION_KEY,
} from "models/questionnaire";
import { THEME_KEY } from "models/theme";
import {
  TOKEN,
  REFRESH_TOKEN,
  SIGNUP_EMAIL_KEY,
  SIGNUP_NAME_KEY,
  RESET_AFTER_PURCHASE_KEY,
  SKIP_TO_INSTALL_KEY,
} from "models/user";
import { isWindow } from "./env";
import { OLD_OS_KEY } from "./platform";

export const getStorage = () => {
  return window.localStorage;
};

// BIG NOTICE: if the value is string, it will still work
function read<T>(key: string): T | undefined {
  if (!isWindow()) {
    return undefined;
  }

  const value = getStorage().getItem(key) || undefined;
  if (value === undefined) {
    return undefined;
  }

  try {
    const json = JSON.parse(value);
    if (json === null) {
      // special case
      return undefined;
    }
    return json as T;
  } catch (err) {
    return undefined;
  }
}

function write<T>(key: string, value: T) {
  if (isWindow()) {
    getStorage().setItem(key, JSON.stringify(value));
  }
}

function clear(key: string) {
  if (isWindow()) {
    getStorage().removeItem(key);
  }
}

export { read, write, clear };

// removes all keys in memory
export const resetStorage = () => {
  // remove some existing caches
  clear(TOKEN);
  clear(REFRESH_TOKEN);
  clear(CONFIG_STATE_KEY);
  clear(SIGNUP_EMAIL_KEY);
  clear(SIGNUP_NAME_KEY);
  clear(PAYMENT_RETRY_URL_KEY);
  clear(THEME_KEY);
  clear(OLD_OS_KEY);
  clear("COOKIE_CONSENT_TYPE"); // this will be removed after deciding which consent to continue with

  // and the rest
  clearUserSelectionsFromState();
};

// note: this is a subset of the resetStorage and aims to clear only temporary kind of data
export const clearUserSelectionsFromState = () => {
  clear(TARGET_KEY);
  clear(PRODUCT_KEY);
  clear(STATE_KEY);
  clear(VERSION_KEY);
  clear(QUESTIONNAIRE_KEY);
  clear(PAYMENT_STATUS_KEY);
  clear(RESET_AFTER_PURCHASE_KEY);
  clear(SKIP_TO_INSTALL_KEY);
};
