import QuestionnaireProvider from "providers/questionnaire";
import QuestionnaireUpdateEmailView from "./view";
import { useParams } from "react-router";
import { QuestionnaireVersion } from "models/questionnaire";
import StateProvider from "providers/state";
import ConfigProvider from "providers/config";
import { StaticViewIdMap } from "models/question";

const QuestionnaireUpdateEmailViewWrapper = () => {
  const { version: v } = useParams();
  const version = v as QuestionnaireVersion;

  return (
    <ConfigProvider>
      <StateProvider>
        <QuestionnaireProvider
          version={version}
          questionId={StaticViewIdMap["update-email"]}
        >
          <QuestionnaireUpdateEmailView />
        </QuestionnaireProvider>
      </StateProvider>
    </ConfigProvider>
  );
};

export default QuestionnaireUpdateEmailViewWrapper;
