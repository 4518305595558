import { TranslationModel } from "models/locale";

const esTranslations: TranslationModel = {
  link_contact_us: "https://masterenglish.zendesk.com/hc/es-mx",
  link_refund:
    "https://masterenglish.zendesk.com/hc/es-mx/articles/10485682004626--C%C3%B3mo-puedo-obtener-un-reembolso-",

  loading: "Cargando",

  validator_at_least_8: "8 o más caracteres",
  validator_invalid_email:
    "Introduce una dirección de correo electrónico válida",
  validator_required: "Este campo es obligatorio",
  validator_phone: "Introduce un número válido con prefijo",

  old_os_text1:
    "La aplicación Master English admite los siguientes sistemas operativos:",
  old_os_text2:
    "iOS: versión 14.0 o posterior\nAndroid: versión 11.0 o posterior",
  old_os_text3: "Por favor, actualice su sistema operativo antes de continuar.",
  old_os_title: "Tu sistema operativo no es compatible",
  old_os_button: "Aceptar",

  footer_menu_help: "Ayuda",
  footer_menu_product: "Producto",
  footer_menu_social: "Redes sociales",
  footer_contact_us: "Contáctanos",
  footer_main_title: "Programa Master English",
  footer_privacy_policy: "Política de Privacidad",
  footer_terms: "Términos de Uso",
  footer_manage_plan: "Gestiona tu suscripción",
  footer_refund: "Póliza de reembolso",
  label_email: "Email",
  label_firstname: "Nombre",
  label_password: "Contraseña",

  title_404: "Error 404",
  text_404: "No se ha podido encontrar la página.",
  link_404: "Intenta ir a masterenglish.com.",

  terms_of_use: "Términos de Uso",

  account_exists_body:
    "Si olvidaste tu contraseña, ingresa tu email y haz clic en Continuar. Te enviaremos un link para iniciar sesión.",

  account_exists_button: "Continuar",
  account_exists_title: "Esta cuenta ya existe",
  calculating: "Calculando",
  check_email_button1: "Sí",
  check_email_button2: "No, quiero modificarlo",
  check_email_p1: "Lo necesitarás para iniciar sesión en tu programa.",
  check_email_title: "¿Es correcto?",
  congratulations_button1: "¡Estoy más que listo!",
  congratulations_button2: "¡Estoy listo!",
  congratulations_text:
    "Creemos que has tomado una muy buena decisión al querer invertir en tu aprendizaje de inglés.<br /><br />No se trata solo de aprender un idioma, estás invirtiendo en ti y en tu futuro.<br /><br />Tenemos todo listo para comenzar tu aprendizaje y llevarte hasta un nivel avanzado de inglés.<br /><br />¿Estás listo para empezar?",
  congratulations_title: "¡Enhorabuena!",

  dayselection_done: "¡Listo! Ya puedes continuar.",
  dayselection_in_progres: "Continúa después de seleccionar ##DAYS## días.",
  email_error_body: "Dirección de email #EMAIL# no encontrada.",
  email_error_button1: "Inténtalo de nuevo",
  email_error_button2: "Crea una cuenta",
  email_error_title: "¡No encontrada!",
  email_exists_button1: "Inténtalo de nuevo",
  email_exists_p1:
    "La dirección de email #EMAIL# ya está reservada para otra cuenta.",
  email_exists_title: "Este email ya existe",
  follow_steps_p3:
    "Inicia sesión con #EMAIL# y la contraseña que acabas de crear.",
  follow_steps_p4: "Completa el test de nivel inicial.",
  follow_steps_title: "Sigue estos pasos",
  footer_fb_alt: "Link de Facebook",
  footer_ig_alt: "Link de Instagram",
  hint_agree_on_terms_and_privacy:
    "Al continuar, aceptas nuestros #LINK_TOS# y #PRIVACY_LINK#.",
  level_test_button: "Siguiente",
  level_test_text:
    "Para garantizar una óptima conexión a Internet y uso del micrófono, completarás tu test de nivel inicial desde nuestra app móvil.<br /><br />Después de completarlo, obtendrás tu programa personalizado de inglés.",
  level_test_title: "Test de nivel inicial",
  link_sent_body:
    "Te hemos enviado un link para iniciar sesión a #EMAIL#. Haz clic en el link que aparecerá en tu email.",
  link_sent_title: "Link enviado",
  login_body:
    "Ingresa tu email y haz clic en Continuar. Te enviaremos un link para iniciar sesión.",
  login_button: "Continuar",
  login_button_page_body:
    "Si no tienes la app de Master English, descarga la app.",
  login_button_page_button: "Iniciar sesión",
  login_button_page_link: "descarga la app",
  login_button_page_title: "Iniciar sesión en la app",
  login_link_expired_body:
    "Tu link ha caducado. Por favor, haz clic en Continuar para obtener un nuevo link.",
  login_link_expired_button: "Continuar",
  login_link_expired_title: "Link caducado",
  login_title: "Iniciar sesión",
  login_title_forgot: "¿Olvidaste tu contraseña?",
  login_divider: "Todavía no tengo una cuenta",
  login_button_create_account: "Crear cuenta",
  login_button_start: "Iniciar sesión",
  login_button_help: "¿Necesitas ayuda?",

  confirm_title: "Confirmación",
  confirm_desc:
    "Estás a punto de reiniciar tu progreso acumulado en Master English para comenzar un nuevo programa. ¿Es esta tu intención?",
  confirm_button_yes:
    "Sí, quiero reiniciar mi progreso y comenzar un nuevo programa",
  confirm_button_no: "No, no quiero reiniciar mi progreso",

  handle_subcription_title: "Gestiona tu suscripción",
  handle_subcription_description:
    "Gestiona tu suscripción al programa Master English en esta página.",
  handle_subcription_p1:
    "Empieza una suscripción para acceder a tu programa Master English. Una vez iniciada tu suscripción solo tendrás que descargar nuestra app Master English en tu dispositivo móvil iPhone o Android e iniciar sesión.",
  handle_subcription_p2:
    'Si quieres lograr un nivel avanzado de inglés con Master English pero todavía no tienes tu programa, <a href="#LINK#">empieza aquí</a>.',
  handle_subcription_cancel_title: "Cómo cancelar tu suscripción",
  handle_subcription_cancel_p1:
    "Tu suscripción se renueva automáticamente hasta que la canceles.",
  handle_subcription_cancel_p2:
    "Puedes cancelar tu suscripción en cualquier momento siguiendo estos pasos:",
  handle_subcription_cancel_item1:
    'Abre la <a href="#LINK#">app Master English</a> y ve al menú de la izquierda',
  handle_subcription_cancel_item2:
    'Selecciona <span style="font-weight: 600; color: #050505;">Configuración</span>',
  handle_subcription_cancel_item3:
    "Ve a <span style='font-weight: 600; color: #050505;'>Administrar suscripción</span>",
  handle_subcription_initial_text1:
    "Si iniciaste tu suscripción desde nuestro <strong>sitio web:</strong>",
  handle_subcription_initial_item1:
    "Haz clic en el botón <span style='font-weight: 600; color: #050505;'>Administrar suscripción</span>",
  handle_subcription_initial_item2:
    "Serás dirigido a nuestro sitio web donde podrás <span style='font-weight: 600; color: #050505;'>cancelar</span> tu suscripción",
  handle_subcription_initial_text2:
    "Si iniciaste tu suscripción dentro de la <strong>app Master English:</strong>",
  handle_subcription_initial_item3:
    "Serás dirigido al menú <span style='font-weight: 600; color: #050505;'>Suscripciones</span> de tu <span style='font-weight: 600; color: #050505;'>Apple App Store</span> o <span style='font-weight: 600; color: #050505;'>Google Play Store</span> desde donde podrás cancelar tu suscripción",
  handle_subcription_refund:
    "Si no estás satisfecho con tu compra, contáctanos a <a href='mailto:soporte@masterenglish.com'>soporte@masterenglish.com</a> dentro de los 30 días posteriores a tu cargo de suscripción y te haremos un reembolso.",
  handle_subcription_help_title: "¿Necesitas ayuda?",
  handle_subcription_help_text:
    "Si tienes dudas o necesitas ayuda para cancelar tu suscripción, envíanos un correo a <a href='mailto:soporte@masterenglish.com'>soporte@masterenglish.com</a>y estaremos encantados de ayudarte. Responderemos a tu mensaje en un plazo de 2 días.",

  reactivate_title: "Reactiva tu programa de inglés",
  reactivate_objectives:
    "<span style='color: white; font-weight: 600;'>Objetivos:</span>",
  reactivate_objective:
    "Conseguir <span style='color: #007AFF;'>un nivel avanzado</span> de inglés",
  reactivate_text:
    "Una vez que hayas reactivado tu programa, podrás ver en la aplicación móvil cuántas semanas de práctica te quedan para alcanzar un nivel avanzado.",
  reactivate_list_title_1: "Áreas de aprendizaje de inglés:",
  reactivate_list_title_2: "Cómo funciona:",
  reactivate_list_text_1: "Speaking, Listening, Reading, Writing y Grammar",
  reactivate_list_text_2:
    "Hemos guardado tu progreso anterior. Tu programa continuará desde donde lo dejaste. Después de que hayas reactivado tu programa, podrás iniciar sesión en la aplicación Master English con tu iPhone o teléfono Android. Nuestras lecciones personalizadas te permitirán ponerte al día rápidamente.",
  reactivate_payment_type:
    "Tu suscripción se renovará mensualmente hasta que la canceles. Puedes cancelar tu suscripción y tu programa en cualquier momento.\n\nTe enviaremos un email 3 días antes de que se cumplan los ${months} meses que dura tu programa para que te acuerdes de cancelar tu suscripción antes de que se renueve el #MONTHS# º mes, si así lo deseas.",
  reactivate_payment_description:
    "Sin compromiso. Cancela en cualquier momento.",
  reactivate_payment_header:
    "Selecciona un método de pago para reactivar tu programa",
  reactivate_payment_title:
    "Inicia una suscripción mensual para reactivar tu programa",
  reactivate_payment_footer:
    "Puedes cancelar tu suscripción y tu programa en cualquier momento. Para ello, envíanos un email a soporte@masterenglish.com.",
  reactivate_reviews: "Nuestra valoración en la App Store es de 4.9/5.",

  reactivate_or_new_title: "Programa pausado",
  reactivate_or_new_desc1: "Tu programa Master English está en pausa.",
  reactivate_or_new_desc2: "¿Qué te gustaría hacer?",
  reactivate_or_new_button_reactivate:
    "Reactivar mi programa existente y continuar desde donde lo dejé",
  reactivate_or_new_button_new: "Quiero empezar un nuevo programa",

  menu_item1: "Empieza hoy",
  menu_item2: "Iniciar sesión",
  menu_item3: "Gestiona tu plan",
  meta_description_front_page:
    "Master English te lleva de nivel básico o intermedio a hablar inglés con fluidez profesional total. Siéntete seguro en cualquier conversación. ¡Empieza hoy!",
  meta_description_licenses_page:
    "El siguiente software de código abierto y bibliotecas de terceros participaron en la creación de esta aplicación.",
  meta_description_privacy_page:
    "Tu privacidad es importante. Nuestra Política de Privacidad incluye cómo recogemos, usamos, divulgamos, transmitimos y almacenamos tu información personal.",
  meta_description_purchase_error_page:
    "No se pudo procesar su transacción. Por favor, inténtalo de nuevo.",
  meta_description_terms_page:
    "Master English/WordDive S.L. ofrece servicios y productos a sus usuarios, como el programa Master English, de acuerdo con los presentes Términos de Uso.",
  meta_description_thank_you_page:
    "Gracias por elegir Master English. Descarga la aplicación y empieza a aprender.",
  months: "meses",
  next_up_button: "Entendido",
  next_up_text:
    "Gracias a tus respuestas a nuestro cuestionario, ya tenemos una idea clara sobre cuál es tu nivel de inglés.<br /><br />A continuación, te pediremos que completes un <span style='color: #007aff;'>Test de nivel inicial</span> para confirmar tu nivel de forma precisa.<br /><br />A partir de tus resultados, le haremos los últimos retoques a tu programa.",
  next_up_title: "Siguiente paso",
  page_title_front_page:
    "Habla inglés con fluidez profesional | Tu programa en línea",
  page_title_licenses: "Licencias",
  page_title_privacy: "Política de Privacidad",
  page_title_purchase_error: "Algo salió mal",
  post_purchase_button1: "Verificar email",
  post_purchase_p1:
    "A continuación, vamos a verificar que tu email es correcto.",
  post_purchase_title: "¡Hemos creado tu programa!",
  purchase_error_button: "Volver a intentar",
  purchase_error_p1: "No se pudo procesar su transacción.",
  purchase_error_title: "¡Ups! Algo salió mal",
  purchase_loader: "Preparando opción de pago",
  section_1_button: "Empieza hoy",
  section_1_description_p1_embedded:
    "Master English es una aplicación diseñada para hispanohablantes que desean alcanzar un nivel avanzado de inglés.",
  section_1_description_p2_embedded:
    "Al completar el programa Master English, serás capaz de mantener conversaciones en inglés con confianza y aplicar a ofertas de trabajo donde el inglés sea un requisito.",
  section_1_title:
    "Aprende <br responsive mobile />a hablar inglés <br />con fluidez",
  section_2_description_p1_embedded:
    "Nos especializamos en ayudar a adultos hispanohablantes que desean alcanzar un nivel avanzado de inglés y destacar en su trabajo.",
  section_2_description_p2_embedded:
    "No importa si tu nivel actual es principiante, básico, intermedio o superior; te ayudaremos a lograr un nivel avanzado de la manera más efectiva posible.",
  section_2_description_p3_embedded:
    "Además, no estarás limitado por horarios ni ubicaciones. Basándonos en tu nivel de inglés y necesidades, te daremos un programa personalizado que podrás completar de forma independiente en línea con la aplicación Master English.",
  section_2_title: "¿Para quién es Master English?",
  section_3_description_bullet_1: "5 lecciones de 30 min por semana",
  section_3_description_bullet_1_bold: "Horario 1:",
  section_3_description_bullet_2_bold: "Horario 2:",
  section_3_description_bullet_2_method: "3 lecciones de 50 min por semana",
  section_3_description_bullet_3: "5 lecciones de 60 min por semana",
  section_3_description_bullet_3_bold: "Horario intensivo:",
  section_3_description_p1_embedded:
    "Primero, te pedimos que contestes un breve cuestionario para conocer tu nivel de inglés y tus áreas de dificultad.",
  section_3_description_p2_embedded:
    "Luego, podrás elegir el horario que mejor se adapte a ti:",
  section_3_description_p3_embedded:
    "A partir de ahí, te crearemos un programa personalizado y te diremos cuánto tiempo te llevará alcanzar un nivel avanzado de inglés. En general, tomará entre 6 y 12 meses dependiendo de tu nivel inicial y ritmo de aprendizaje.",
  section_3_description_p4_embedded:
    "Una vez creado tu programa, podrás acceder a él a través de la aplicación Master English. A medida que avances y practiques con la app, iremos conociendo mejor tu ritmo de aprendizaje. Esto nos permitirá optimizar y personalizar aún más tus lecciones para ayudarte a mejorar tu inglés lo más rápido posible.",
  section_3_title: "¿Cómo funciona el programa Master English?",
  section_4_description_p1_embedded:
    "Nuestro programa ha sido desarrollado a partir de un exhaustivo estudio y análisis de los problemas más comunes que enfrentan los hispanohablantes al aprender inglés.",
  section_4_description_p2_embedded:
    "Nuestro enfoque de aprendizaje y nuestras lecciones están diseñados por un equipo galardonado de expertos en inglés. Además, todos nuestros profesores poseen maestrías en pedagogía y lingüística.",
  section_4_description_p3_embedded:
    "Contamos con un equipo de hablantes nativos de inglés que nos proporciona modelos para nuestros ejercicios de conversación y comprensión auditiva.",
  section_4_description_p4_embedded:
    "Nuestra tecnología de aprendizaje, patentada en Estados Unidos, detecta tu velocidad de aprendizaje y áreas de dificultad. Con esta información, personaliza tus lecciones para garantizar resultados permanentes.",
  section_4_title: "¿Por qué es tan efectivo Master English?",
  section_5_description_p1_embedded:
    "Sin importar en dónde vivas, hablar bien en inglés es indispensable en el mundo profesional.",
  section_5_description_p2_embedded:
    "Constantemente vemos cómo gente talentosa y capaz pierde oportunidades laborales porque su inglés no es lo suficientemente bueno.",
  section_5_description_p3_embedded:
    "Es por eso que, después de ver que esto se repetía año tras año, decidimos tomar cartas en el asunto. Reunimos a un equipo de 45 especialistas altamente cualificados en la enseñanza de inglés, desarrollo de software, diseño UX, diseño e inteligencia artificial, y creamos el programa en línea Master English.",
  section_5_description_p4_embedded:
    "Nuestro objetivo era, y sigue siendo, ofrecer una solución efectiva para aquellas personas interesadas en obtener una fluidez profesional en inglés, y en línea.",
  section_5_description_p5_embedded:
    "Esperamos que con nuestro programa logres convertir tus sueños en realidad.",
  section_5_title: "¿Por qué creamos Master English?",
  section_6_button: "Empieza hoy",
  section_6_description_p1_embedded:
    "Probablemente, has pasado mucho tiempo batallando con tu inglés, pensando en cómo mejorarlo y probando diferentes métodos de aprendizaje que no te llevaron a ningún sitio. Es por eso que estás aquí, ¿verdad? Bueno, no queremos que pierdas más oportunidades por no tener el nivel de inglés que necesitas.",
  section_6_description_p2_embedded:
    "De ahora en adelante, queremos que enfoques toda tu energía en mejorar tu inglés sistemáticamente, semana tras semana, para que al completar el programa, tengas cientos de oportunidades donde elegir.",
  section_6_description_p3_embedded:
    "Comienza hoy tu camino hacia un nivel avanzado de inglés con la aplicación Master English. Es una decisión de la que nunca te arrepentirás. ¡Nos vemos al otro lado!",
  section_6_title_v28: "Crea tu programa hoy mismo",
  subscription_email_link: "soporte@masterenglish.com",
  thank_you_button: "Descargar Master English",
  thank_you_p2:
    "Haz clic en el botón de abajo para descargar la app de Master English.",
  thank_you_p3:
    "Abre la app y haz clic en “Inicia sesión” (observa la imagen).",
  update_email_button1: "Actualizar",
  update_email_p1: "Asegúrate de escribir tu email correctamente.",
  update_email_title: "Actualiza tu email",
};

export default esTranslations;
