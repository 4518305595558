import View from "components/layout/view";
import Navbar from "components/navigation/navbar";
import Title from "components/title";
import { Fragment, FunctionComponent } from "react";
import Description from "components/description";
import { read } from "lib/storage";
import { useTranslations } from "hooks/translations";
import ActionContainer, { ActionType } from "components/layout/actions";
import ContentContainer from "components/layout/content";
import Tiles from "components/tiles";
import { PAYMENT_RETRY_URL_KEY } from "models/payment";
import { useNavigate } from "react-router";

const PaymentFailureView: FunctionComponent = () => {
  const t = useTranslations();
  const retryUrl = read<string>(PAYMENT_RETRY_URL_KEY) || "/";
  const navigate = useNavigate();

  const handleRetry = () => {
    navigate(retryUrl);
  };

  const actions: ActionType[] = [
    {
      type: "button",
      button: {
        text: t("purchase_error_button"),
        onClick: handleRetry,
        type: "primary",
        href: retryUrl,
      },
    },
  ];

  return (
    <Fragment>
      <View
        noindex
        title={t("page_title_purchase_error")}
        description={t("meta_description_purchase_error_page")}
        cover
      >
        <Tiles />
        <Navbar />
        <ContentContainer>
          <Title>{t("purchase_error_title")}</Title>
          <Description>{t("purchase_error_p1")}</Description>
        </ContentContainer>
        <ActionContainer actions={actions} />
      </View>
    </Fragment>
  );
};

export default PaymentFailureView;
