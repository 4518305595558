import HelmetWrapper from "components/helmet";
import { usePageLocale, useRoute, useRouteParams } from "hooks/route";
import { LocaleType } from "models/locale";

const AlternateUrls = () => {
  const route = useRoute();
  const params = useRouteParams();
  const pageLocale = usePageLocale();

  if (!route) {
    return null;
  }

  const alternatives: any[] = [];

  const { pathSets } = route;
  for (const uncastedLocale in pathSets) {
    const locale = uncastedLocale as LocaleType;

    // ignore the same lanauge
    if (locale === pageLocale) {
      continue;
    }

    // Add alternative
    const set = pathSets[locale];
    if (set) {
      let { path } = set;
      for (const key in params) {
        path = path.replace(`:${key}`, params[key] || "");
      }

      alternatives.push({ locale, path: `/${locale}/${path}` });
    }
  }

  return (
    <HelmetWrapper>
      {alternatives.map((alternative) => (
        <link
          key={alternative.locale}
          rel="alternate"
          hrefLang={alternative.locale}
          href={alternative.path}
        />
      ))}
    </HelmetWrapper>
  );
};

export default AlternateUrls;
