export type CountryType =
  | "US"
  | "MX"
  | "AR"
  | "EC"
  | "SV"
  | "PA"
  | "PR"
  | "BO"
  | "CL"
  | "CO"
  | "CR"
  | "DO"
  | "GT"
  | "HN"
  | "NI"
  | "PY"
  | "PE"
  | "UY"
  | "VE";

export type LocaleType = "es" | "pt";

// this is a list of allowed locales
export const Locales: LocaleType[] = ["es", "pt"];
export const FallbackLocale: LocaleType = "es";

export type Language = "en-US" | "es-419" | "pt-BR";
export type TranslateFn = (key: TranslationKey) => string;

export type TranslationKey =
  | "link_contact_us"
  | "link_refund"
  | "loading"
  | "validator_at_least_8"
  | "validator_invalid_email"
  | "validator_required"
  | "validator_phone"
  | "old_os_text1"
  | "old_os_text2"
  | "old_os_text3"
  | "old_os_title"
  | "old_os_button"
  | "footer_main_title"
  | "footer_menu_product"
  | "footer_menu_help"
  | "footer_menu_social"
  | "footer_privacy_policy"
  | "footer_contact_us"
  | "footer_terms"
  | "footer_manage_plan"
  | "footer_refund"
  | "footer_fb_alt"
  | "footer_ig_alt"
  | "label_email"
  | "label_firstname"
  | "label_password"
  | "title_404"
  | "text_404"
  | "link_404"
  | "terms_of_use"
  | "account_exists_body"
  | "account_exists_button"
  | "account_exists_title"
  | "calculating"
  | "check_email_button1"
  | "check_email_button2"
  | "check_email_p1"
  | "check_email_title"
  | "congratulations_button1"
  | "congratulations_button2"
  | "congratulations_text"
  | "congratulations_title"
  | "dayselection_done"
  | "dayselection_in_progres"
  | "email_error_body"
  | "email_error_button1"
  | "email_error_button2"
  | "email_error_title"
  | "email_exists_button1"
  | "email_exists_p1"
  | "email_exists_title"
  | "follow_steps_p3"
  | "follow_steps_p4"
  | "follow_steps_title"
  | "hint_agree_on_terms_and_privacy"
  | "level_test_button"
  | "level_test_text"
  | "level_test_title"
  | "link_sent_body"
  | "link_sent_title"
  | "login_body"
  | "login_button"
  | "login_button_page_body"
  | "login_button_page_button"
  | "login_button_page_link"
  | "login_button_page_title"
  | "login_link_expired_body"
  | "login_link_expired_button"
  | "login_link_expired_title"
  | "login_title"
  | "login_title_forgot"
  | "login_divider"
  | "login_button_create_account"
  | "login_button_start"
  | "login_button_help"
  | "handle_subcription_title"
  | "handle_subcription_description"
  | "handle_subcription_p1"
  | "handle_subcription_p2"
  | "handle_subcription_cancel_title"
  | "handle_subcription_cancel_p1"
  | "handle_subcription_cancel_p2"
  | "handle_subcription_cancel_item1"
  | "handle_subcription_cancel_item2"
  | "handle_subcription_cancel_item3"
  | "handle_subcription_initial_text1"
  | "handle_subcription_initial_item1"
  | "handle_subcription_initial_item2"
  | "handle_subcription_initial_text2"
  | "handle_subcription_initial_item3"
  | "handle_subcription_refund"
  | "handle_subcription_help_title"
  | "handle_subcription_help_text"
  | "reactivate_title"
  | "reactivate_objectives"
  | "reactivate_objective"
  | "reactivate_text"
  | "reactivate_list_title_1"
  | "reactivate_list_title_2"
  | "reactivate_list_text_1"
  | "reactivate_list_text_2"
  | "reactivate_payment_type"
  | "reactivate_payment_description"
  | "reactivate_payment_header"
  | "reactivate_payment_title"
  | "reactivate_payment_footer"
  | "reactivate_reviews"
  | "reactivate_or_new_title"
  | "reactivate_or_new_desc1"
  | "reactivate_or_new_desc2"
  | "reactivate_or_new_button_reactivate"
  | "reactivate_or_new_button_new"
  | "confirm_title"
  | "confirm_desc"
  | "confirm_button_yes"
  | "confirm_button_no"
  | "menu_item1"
  | "menu_item2"
  | "menu_item3"
  | "meta_description_front_page"
  | "meta_description_licenses_page"
  | "meta_description_privacy_page"
  | "meta_description_purchase_error_page"
  | "meta_description_terms_page"
  | "meta_description_thank_you_page"
  | "months"
  | "next_up_button"
  | "next_up_text"
  | "next_up_title"
  | "page_title_front_page"
  | "page_title_licenses"
  | "page_title_privacy"
  | "page_title_purchase_error"
  | "post_purchase_button1"
  | "post_purchase_p1"
  | "post_purchase_title"
  | "purchase_error_button"
  | "purchase_error_p1"
  | "purchase_error_title"
  | "purchase_loader"
  | "section_1_button"
  | "section_1_description_p1_embedded"
  | "section_1_description_p2_embedded"
  | "section_1_title"
  | "section_2_description_p1_embedded"
  | "section_2_description_p2_embedded"
  | "section_2_description_p3_embedded"
  | "section_2_title"
  | "section_3_description_bullet_1"
  | "section_3_description_bullet_1_bold"
  | "section_3_description_bullet_2_bold"
  | "section_3_description_bullet_2_method"
  | "section_3_description_bullet_3"
  | "section_3_description_bullet_3_bold"
  | "section_3_description_p1_embedded"
  | "section_3_description_p2_embedded"
  | "section_3_description_p3_embedded"
  | "section_3_description_p4_embedded"
  | "section_3_title"
  | "section_4_description_p1_embedded"
  | "section_4_description_p2_embedded"
  | "section_4_description_p3_embedded"
  | "section_4_description_p4_embedded"
  | "section_4_title"
  | "section_5_description_p1_embedded"
  | "section_5_description_p2_embedded"
  | "section_5_description_p3_embedded"
  | "section_5_description_p4_embedded"
  | "section_5_description_p5_embedded"
  | "section_5_title"
  | "section_6_button"
  | "section_6_description_p1_embedded"
  | "section_6_description_p2_embedded"
  | "section_6_description_p3_embedded"
  | "section_6_title_v28"
  | "subscription_email_link"
  | "thank_you_button"
  | "thank_you_p2"
  | "thank_you_p3"
  | "update_email_button1"
  | "update_email_p1"
  | "update_email_title";

export type TranslationModel = {
  [key in TranslationKey]: string;
};
