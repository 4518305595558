import { FunctionComponent } from "react";
import { createBrowserRouter, Outlet, RouterProvider } from "react-router";
import definitions from "./definitions";
import IdsProvider from "providers/ids";
import ErrorBoundary from "components/error-boundary";
import ThemeProvider from "providers/theme";
import GeoProvider from "providers/geo";
import routeBuilder from "./builder";
import TranslationProvider from "providers/translation";
import LocaleProvider from "providers/locale";
import AlternateUrls from "./alternates";
import LocaleSwitch from "components/locale-switch";
import CanonicalUrl from "./canonical";

const routes = routeBuilder(definitions);

// create a router and wrap some contexts/utilities
const router = createBrowserRouter([
  {
    path: "/",
    children: routes,
    element: (
      <ErrorBoundary>
        <GeoProvider>
          <LocaleProvider>
            <ThemeProvider>
              <IdsProvider>
                <TranslationProvider>
                  <LocaleSwitch />
                  <AlternateUrls />
                  <CanonicalUrl />
                  <Outlet />
                </TranslationProvider>
              </IdsProvider>
            </ThemeProvider>
          </LocaleProvider>
        </GeoProvider>
      </ErrorBoundary>
    ),
  },
]);

// the actual component
const Router: FunctionComponent = () => <RouterProvider router={router} />;

export default Router;
