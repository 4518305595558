import { gtmSignUp, gtmUserId } from "lib/gtm";
import { getVersion } from "lib/questionnaire";
import {
  parseQuestionnaireCompleted,
  parseQuestionnaireVersion,
} from "lib/restore";
import { write } from "lib/storage";
import { ConfigModel } from "models/config";
import { OptionModel } from "models/option";
import { QuestionModel } from "models/question";
import {
  QuestionnaireModel,
  QuestionnaireVersion,
  STATE_KEY,
} from "models/questionnaire";
import { StateModel } from "models/state";
import { TOKEN, REFRESH_TOKEN } from "models/user";
import { fetchEntitlement } from "services/entitlement";
import { signup, SignupPayloadModel } from "services/signup";
import { login } from "services/user";
import { isEntitlementExpired } from "./entitlement";
import { SignupModel } from "models/signup";
import { fetchConfig } from "services/config";
import { NavigateFunction } from "react-router";
import { Language } from "models/locale";

export interface SignupProps {
  state: StateModel;
  questionnaire: QuestionnaireModel;
  question: QuestionModel;
  navigate: NavigateFunction;
  config: ConfigModel;
  option?: OptionModel;
  uiLanguage: Language;

  // tracking info
  campaignId: string | undefined;
  versionId: string | undefined;

  // some context updaters
  updateQuestionnaire: (newQuestionnaire: QuestionnaireModel) => void;
  updateConfig: (newConfig: ConfigModel) => void;
  updateQuestionnaireState: (newState: StateModel) => void;

  // links
  accountExistsLink: string;
  reactivateLink: string;
}

export const signupUser = async (props: SignupProps) => {
  const {
    state,
    questionnaire,
    navigate,
    config,
    uiLanguage,
    updateConfig,
    updateQuestionnaireState,
    campaignId,
    versionId,
    accountExistsLink,
    reactivateLink,
  } = props;

  // payload for the signup
  const { uuid = "", firstname, email, password = "" } = state.user;
  const signUpPayload: SignupPayloadModel = {
    uuid,
    firstname,
    email,
    password,
    uiLanguage,
    questionnaire: getVersion(questionnaire),
  };

  let signupResponse: SignupModel;
  try {
    // signup
    signupResponse = await signup(signUpPayload);

    // send uuid to GTM
    if (signupResponse.user.uuid) {
      gtmUserId(signupResponse.user.uuid);
    }
  } catch (err: any) {
    if (err.cause === 1100) {
      // fetch a new config and use the new uuid and questionnaireVariant
      const newConfig = await fetchConfig(uiLanguage, campaignId, versionId);

      // make a new state object
      const newState = { ...state };
      newState.user = { ...state.user, uuid: newConfig.uuid };

      // recursive call
      await signupUser({
        ...props,
        config: newConfig,
        state: newState,
      });
      return; // exit now, recursive call should handle the issue
    }

    // left over case, signup failed for unknown cause
    navigate(accountExistsLink);
    throw new Error("Signup failed!");
  }

  // also execute login at this point
  try {
    const loginResponse = await login(email, password || "");
    const { token, refreshToken, user } = loginResponse;
    write(TOKEN, token);
    write(REFRESH_TOKEN, refreshToken);

    // overwrite config a bit
    console.info(`Replace config.uuid with UUID = ${user.uuid}`);
    config.uuid = user.uuid || "";
    config.questionnaireVersion = parseQuestionnaireVersion(loginResponse);
    config.completed = parseQuestionnaireCompleted(loginResponse);
    updateConfig(config);

    // update state as well
    state.user.uuid = user.uuid;
    state.signupDone = true;
    write(STATE_KEY, state);
    updateQuestionnaireState(state);

    // send uuid to GTM
    gtmUserId(config.uuid);
  } catch (err) {
    // left over case, could be a wrong password
    navigate(accountExistsLink);
    throw new Error("Login failed!");
  }

  // v69 specific code
  const version = getVersion(questionnaire);
  if (version === QuestionnaireVersion.V69) {
    const entitlement = await fetchEntitlement();
    if (entitlement && isEntitlementExpired(entitlement)) {
      navigate(reactivateLink);
      throw new Error("Redirected to activate view!");
    }
  }

  // registration event to GTM
  await gtmSignUp(email);
};
