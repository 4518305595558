import { saveAs } from "file-saver";
import definititions from "./definitions";
import { LocaleType } from "models/locale";

const getUrlFor = (path: string) => `https://www.masterenglish.com${path}`;

export const downloadSitemapXML = () => {
  const locs: string[] = [];

  // gather a list of urls to show in sitemap
  const sitemapList: string[] = [];
  definititions.forEach((route) => {
    const { visible = false, pathSets } = route;

    if (!visible) {
      return;
    }

    for (const uncastedLocale in pathSets) {
      const locale = uncastedLocale as LocaleType;
      const set = pathSets[locale];
      if (set) {
        // if loc already exists, ignore this round
        const loc = getUrlFor(set.path);
        if (locs.includes(loc)) {
          continue;
        }

        // xml beginninga and <loc> entry
        const url = ["  <url>"];
        url.push(`    <loc>${loc}</loc>`);
        locs.push(loc);

        // iterate to print all language versions
        for (const loc in pathSets) {
          const subset = pathSets[loc as LocaleType];
          if (subset) {
            url.push(
              `    <xhtml:link rel="alternate" hreflang="${loc}" href="${getUrlFor(
                subset.path
              )}" />`
            );
          }
        }

        // xml ending
        url.push("  </url>\n");
        sitemapList.push(url.join("\n"));
      }
    }
  });

  // file contents
  const contents = [
    `<?xml version="1.0" encoding="UTF-8"?>\n`,
    `<urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">\n`,
    ...sitemapList.map((entry) => entry),
    `</urlset>`,
  ];

  // save to file
  saveAs(
    new Blob(contents, {
      type: "text/xml;charset=utf-8",
    }),
    "sitemap.xml"
  );
};
