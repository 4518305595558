import { TranslationKey, TranslationModel } from "models/locale";

// this is used in useTranslate() hook but can be used standalone, if we need translations for known language
export const translate = (
  translations: TranslationModel,
  key: TranslationKey
) => {
  const translation = translations[key];
  if (!translation) {
    return key;
  }
  return translation;
};
