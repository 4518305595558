import { useQuestion } from "hooks/question";
import { StyledText, StyledContainer } from "./footnote.styles";

const Footnote = () => {
  const question = useQuestion();

  const { footnote } = question;
  if (!footnote) {
    return null;
  }

  return (
    <StyledContainer>
      <StyledText>{footnote}</StyledText>
    </StyledContainer>
  );
};

export default Footnote;
