import { Navigate, RouteObject } from "react-router";
import { RouteDefinition, RouteHandle } from "./definitions";
import NotFoundViewWrapper from "views/not-found/wrapper";
import { LocaleType } from "models/locale";

const routeBuilder = (routeDefinitions: RouteDefinition[]): RouteObject[] => {
  const routes: RouteObject[] = [];

  routeDefinitions.forEach((definitition) => {
    const { name, ViewComponent, pathSets } = definitition;

    // create a route definition for every locale version
    for (const uncastedLocale in pathSets) {
      const locale = uncastedLocale as LocaleType;
      const set = pathSets[locale];
      if (set) {
        // standard route without any issues
        const { path, OverrideViewCompoment } = set;
        routes.push({
          path: `/${locale}/${path}`,
          element: OverrideViewCompoment || ViewComponent,
          handle: { name, locale } as RouteHandle,
        });

        // handle root route cases
        if (locale === "es") {
          // spanish is not redirected for now
          routes.push({
            path: `/${path}`,
            element: OverrideViewCompoment || ViewComponent,
            handle: {
              name,
              locale,
              canonical: `/${locale}/${path}`,
            } as RouteHandle,
          });
        } else {
          routes.push({
            path: `/${path}`,
            element: <Navigate replace to={`/${locale}/${path}`} />,
            handle: {
              name: "n/a",
            },
          });
        }
      }
    }
  });

  // add 404
  routes.push({ path: "*", element: <NotFoundViewWrapper /> });

  return routes;
};

export default routeBuilder;
