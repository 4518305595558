import { FunctionComponent } from "react";
import { StyledNudge } from "./nudge.styles";

interface NudgeProps {
  children: any;
  nudge: boolean;
}

const Nudge: FunctionComponent<NudgeProps> = ({ children, nudge }) => {
  return <StyledNudge $nudge={nudge}>{children}</StyledNudge>;
};

export default Nudge;
