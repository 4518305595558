import { useRouteMatch } from "hooks/route";
import { RouteHandle } from "./definitions";
import HelmetWrapper from "components/helmet";

const NoIndex = () => {
  const match = useRouteMatch();

  if (!match || !match.handle) {
    return null;
  }

  const { visible = false } = match.handle as RouteHandle;
  if (!visible) {
    return null;
  }

  return (
    <HelmetWrapper>
      <meta name="robots" content="noindex" />
    </HelmetWrapper>
  );
};

export default NoIndex;
