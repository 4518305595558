import { Language, LocaleType } from "models/locale";
import { OptionModel, OptionType } from "models/option";
import { QuestionModel, QuestionType } from "models/question";
import {
  QuestionnaireModel,
  QuestionnaireVersion,
  STATE_KEY,
  EmbeddedQuestionnaireQuestion,
  SupportedQuestionnaireMap,
  ControlQuestionnaireMap,
  EmbeddedQuestionnaireMap,
} from "models/questionnaire";
import { StateModel } from "models/state";
import { parseFollowupRules } from "./followup";
import { fetchConfig } from "services/config";
import { buildState } from "./state";
import { write } from "./storage";
import { loadCleanQuestionnaire } from "services/questionnaire";

export const getVersion = (questionnaire: QuestionnaireModel) =>
  questionnaire.id;

export const getVersionWithV = (questionnaire: QuestionnaireModel) =>
  `v${getVersion(questionnaire)}`;

export const getAllQuestions = (questionnaire: QuestionnaireModel) =>
  questionnaire.questions;

export const getQuestionByType = (
  questionnaire: QuestionnaireModel,
  type: QuestionType
) => {
  const questions = getAllQuestions(questionnaire);
  return questions.find((q) => q.type === type);
};

export const getPostPurchaseView = (questionnaire: QuestionnaireModel) => {
  const view = getQuestionByType(questionnaire, "PostPurchase");
  if (!view) {
    throw new Error("No PostPurchase found!");
  }
  return view;
};

export const getFirstSignupView = (questionnaire: QuestionnaireModel) => {
  const questions = getAllQuestions(questionnaire);
  const signupQuestions = questions.filter((q) => q.type === "SignupView");
  const first = signupQuestions[0];
  return first;
};

export const getLastSignupView = (questionnaire: QuestionnaireModel) => {
  const questions = getAllQuestions(questionnaire);
  const signupQuestions = questions.filter((q) => q.type === "SignupView");
  const last = signupQuestions[signupQuestions.length - 1];
  return last;
};

export const getInitialLevelQuestion = (questionnaire: QuestionnaireModel) => {
  const view = getQuestionByType(questionnaire, "InitialLevelView");
  if (!view) {
    throw new Error("No InitialLevelView found!");
  }
  return view;
};

export const getTargetLevelQuestion = (questionnaire: QuestionnaireModel) => {
  const view = getQuestionByType(questionnaire, "TargetLevelView");
  return view;
};

export const getLevelQuestions = (questionnaire: QuestionnaireModel) => {
  const levelQuestions = getAllQuestions(questionnaire).filter(
    (q) => q.type === "LevelQuestion"
  );
  return levelQuestions;
};

export const getAnsweredLevelQuestions = (
  questionnaire: QuestionnaireModel,
  state: StateModel
) => {
  const { visitedQuestionIds } = state;

  // get a list of all LevelQuestions
  const questions = getAllQuestions(questionnaire);
  const levelQuestions = questions.filter((q) => q.type === "LevelQuestion");

  // filter down to include only answered ones
  return levelQuestions.filter((q) => visitedQuestionIds.includes(q.id));
};

export const getAnsweredLevelQuestionTypes = (
  questionnaire: QuestionnaireModel,
  state: StateModel
) => {
  const answeredLevelQuestions = getAnsweredLevelQuestions(
    questionnaire,
    state
  );
  const answers: OptionType[] = [];
  answeredLevelQuestions.forEach((question) => {
    // find answer
    const so = state.selectedOptions.find(
      (so) => so.questionId === question.id
    );
    if (so) {
      if (question.options) {
        const option = question.options.find((o) => o.id === so.optionId);
        if (option && option.type) {
          answers.push(option.type);
        }
      }
    }
  });
  return answers;
};

export const getLastAnsweredLevelQuestion = (
  questionnaire: QuestionnaireModel,
  state: StateModel
): QuestionModel | undefined => {
  const answered = getAnsweredLevelQuestions(questionnaire, state).reverse();
  return answered[0];
};

export const getThankYouView = (questionnaire: QuestionnaireModel) => {
  const view = getQuestionByType(questionnaire, "ThankYouView");
  return view;
};

export const getPreparingProgramView = (questionnaire: QuestionnaireModel) => {
  const view = getQuestionByType(questionnaire, "PreparingProgram");
  if (!view) {
    throw new Error("No PreparingProgram found!");
  }
  return view;
};

export const getQuestionById = (
  questionnaire: QuestionnaireModel,
  id: number
) => getAllQuestions(questionnaire).find((q) => q.id === id);

export const getInitialQuestion = (questionnaire: QuestionnaireModel) => {
  const questions = getAllQuestions(questionnaire);
  const view = questions.find((q) => q.id === questionnaire.initialQuestion);
  if (!view) {
    throw new Error("No InitialQuestion found!");
  }
  return view;
};

export const getProgramView = (
  questionnaire: QuestionnaireModel,
  state: StateModel
) => {
  // first ThankYouPage or PreparingProgramVIew
  const thankYouView = getThankYouView(questionnaire);
  const preparingProgramView = getPreparingProgramView(questionnaire);
  const question = thankYouView || preparingProgramView;

  // we might have special rules
  if (question.followUps) {
    const id = parseFollowupRules(questionnaire, question.followUps, state);
    if (id) {
      const view = getQuestionById(questionnaire, id);
      if (view) {
        return view;
      }
    }
  }

  // check by normal option
  if (question.options) {
    const option = question.options[question.options.length - 1];
    if (option && option.followUpQuestion) {
      const view = getQuestionById(questionnaire, option.followUpQuestion);
      if (view) {
        return view;
      }
    }
  }

  throw new Error("No ProgramView found!");
};

export const isFinished = (state: StateModel) => {
  return state.finished === true;
};

export const isQuestionnaireSupported = (
  locale: LocaleType,
  version: QuestionnaireVersion
) => SupportedQuestionnaireMap[locale].includes(version);

export const getControlQuestionnaireVersion = (locale: LocaleType) =>
  ControlQuestionnaireMap[locale];

export const getEmbeddedQuestionnaireVersion = (locale: LocaleType) =>
  EmbeddedQuestionnaireMap[locale];

export const getEmbeddedQuestionnaire = async (locale: LocaleType) => {
  const version = getEmbeddedQuestionnaireVersion(locale);
  const questionnaire = await loadCleanQuestionnaire(
    "",
    version,
    locale,
    false
  );
  return questionnaire;
};

const getFirstOption = (question: QuestionModel) => {
  const { options = [] } = question;
  return options[0];
};

export const createEmbeddedQuestionnaireState = async (
  locale: LocaleType,
  uiLanguage: Language,
  campaignId: string | undefined,
  versionId: string | undefined,
  option?: OptionModel
) => {
  // fetch config
  const config = await fetchConfig(uiLanguage, campaignId, versionId);

  // build a new state
  const questionnaire = await getEmbeddedQuestionnaire(locale);
  const question = getQuestionById(
    questionnaire,
    EmbeddedQuestionnaireQuestion
  );

  if (!question) {
    throw new Error("No embedded question found!");
  }

  // the choose the option
  const selectedOption = option || getFirstOption(question);
  const state = buildState(locale, config);
  state.id = questionnaire.id;
  state.visitedQuestionIds.push(question.id);
  state.selectedOptions.push({
    questionId: question.id,
    optionId: selectedOption.id,
  });

  // store state
  write(STATE_KEY, state);
};
