import { FunctionComponent, useEffect, useState } from "react";
import { Navigate, useParams } from "react-router";
import { QuestionnaireVersion } from "models/questionnaire";
import { loadCleanQuestionnaire } from "services/questionnaire";
import { useLocale } from "hooks/geo";
import { useQuestionLink } from "hooks/route";

const QuestionnaireVersionView: FunctionComponent = ({}) => {
  const { version } = useParams();
  const v = version as QuestionnaireVersion;
  const locale = useLocale();
  const [question, toggleQuestion] = useState<number | undefined>(undefined);
  const url = useQuestionLink(v, question || 2);

  useEffect(() => {
    const fn = async () => {
      // load the questionnaire
      const questionnaire = await loadCleanQuestionnaire("", v, locale, false);
      toggleQuestion(questionnaire.initialQuestion);
    };
    fn();
  }, []);

  if (!question) {
    return null;
  }

  return <Navigate to={url} />;
};

export default QuestionnaireVersionView;
