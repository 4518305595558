import { LocaleType } from "models/locale";

// wrappers
import HandleYourSubscriptionViewWrapper from "views/subscription/handle-your-subscription/wrapper";
import HomeViewWrapper from "views/home/wrapper";
import QuestionnaireRaffleViewWrapper from "views/questionnaire/raffle/wrapper";
import QuestionnaireVersionViewWrapper from "views/questionnaire/version/wrapper";
import QuestionnaireQuestionViewWrapper from "views/questionnaire/question/wrapper";
import ForgotPasswordViewWrapper from "views/forgot-password/forgot/wrapper";
import RecoverPasswordViewWrapper from "views/forgot-password/recover/wrapper";
import AppDashboardViewWrapper from "views/app/dashboard/wrapper";
import AppLinkViewWrapper from "views/app/link/wrapper";
import AppExerciseViewWrapper from "views/app/exercise/wrapper";
import TermsOfUseViewWrapper from "views/legal/terms-of-use/wrapper";
import PrivacyPolicyViewWrapper from "views/legal/privacy-policy/wrapper";
import LicensesViewWrapper from "views/legal/licenses/wrapper";
import LinkLoginViewWrapper from "views/link/login/wrapper";
import PaymentFailureViewWrapper from "views/payment/failure/wrapper";
import QuestionnaireGenerateViewWrapper from "views/questionnaire/generate/wrapper";
import QuestionnaireResetViewWrapper from "views/questionnaire/reset/wrapper";
import QuestionnaireUpdateEmailViewWrapper from "views/questionnaire/update-email/wrapper";
import QuestionnaireEmailExistsViewWrapper from "views/questionnaire/email-exists/wrapper";
import QuestionnaireStartLevelTestViewWrapper from "views/questionnaire/start-level-test/wrapper";
import QuestionnaireCongratulationsViewWrapper from "views/questionnaire/congratulations/wrapper";
import QuestionnaireThankYouViewWrapper from "views/questionnaire/thank-you/wrapper";
import QuestionnaireNewConfirmViewWrapper from "views/questionnaire/new-confirm/wrapper";
import QuestionnairePostPurchaseViewWrapper from "views/questionnaire/post-purchase/wrapper";
import QuestionnaireReactivateOrNewViewWrapper from "views/questionnaire/reactivate-or-new/wrapper";
import QuestionnaireReactivateViewWrapper from "views/questionnaire/reactivate/wrapper";
import QuestionnaireNextStepViewWrapper from "views/questionnaire/next-step/wrapper";
import QuestionnaireInitialLevelTestViewWrapper from "views/questionnaire/initial-level-test/wrapper";
import QuestionnaireLoginWithPasswordViewWrapper from "views/questionnaire/login-with-password/wrapper";
import QuestionnaireVerifyEmailViewWrapper from "views/questionnaire/verify-email/wrapper";
import LoginFormViewWrapper from "views/login/form/wrapper";
import LoginSuccessViewWrapper from "views/login/success/wrapper";
import LoginExpiredViewWrapper from "views/login/expired/wrapper";
import LoginFailureViewWrapper from "views/login/failure/wrapper";
import LoginExistsViewWrapper from "views/login/exists/wrapper";

export interface RouteHandle {
  name?: RouteNameType;
  locale?: LocaleType;
  canonical?: string;
  visible?: boolean;
}

export interface RoutePath {
  // path for this locale version
  path: string;

  // optional element, can be used to override the main component
  OverrideViewCompoment?: JSX.Element;
}

// pathSet type for the route
export type RoutePathSet = Partial<Record<LocaleType, RoutePath | undefined>>;

export type RouteNameType =
  | "home"
  | "handle-your-sub"
  | "privacy"
  | "terms"
  | "licenses"
  | "link-login"
  | "login-link"
  | "login-link-sent"
  | "login-expired"
  | "login-failed"
  | "login-account-exists"
  | "raffle"
  | "generate"
  | "reset"
  | "forgot-password"
  | "recover-password"
  | "account-exists"
  | "app-dashboard"
  | "app-exercise"
  | "app-forgot-password"
  | "questionnaire"
  | "question"
  | "questionnaire-post-purchase"
  | "questionnaire-thank-you"
  | "questionnaire-verify-email"
  | "questionnaire-update-email"
  | "questionnaire-email-exists"
  | "questionnaire-congratulations"
  | "questionnaire-next-step"
  | "questionnaire-start-level-test"
  | "questionnaire-initial-level-test"
  | "questionnaire-reactivate-or-new"
  | "questionnaire-reactivate"
  | "questionnaire-new-confirm"
  | "questionnaire-login-with-password"
  | "payment-failed";

export interface RouteDefinition {
  // just a name for the route
  name: RouteNameType;

  // the view to render
  ViewComponent: JSX.Element;

  // if true, the generated route definition will be lazy loaded
  lazy?: boolean;

  // each language version of the path
  pathSets: RoutePathSet;

  // if true, shows on sitemap
  // if false, is added to robots.txt
  visible?: boolean;
}

const definititions: RouteDefinition[] = [
  {
    name: "home",
    ViewComponent: <HomeViewWrapper />,
    visible: true,
    pathSets: {
      es: { path: "" },
      pt: { path: "" },
    },
  },
  {
    name: "handle-your-sub",
    ViewComponent: <HandleYourSubscriptionViewWrapper />,
    visible: true,
    pathSets: {
      es: { path: "gestiona-tu-plan" },
      pt: { path: "gerencie-seu-plano" },
    },
  },
  {
    name: "terms",
    ViewComponent: <TermsOfUseViewWrapper />,
    pathSets: {
      es: { path: "terminos-de-uso" },
      pt: { path: "termos-de-uso" },
    },
  },
  {
    name: "privacy",
    ViewComponent: <PrivacyPolicyViewWrapper />,
    visible: true,
    pathSets: {
      es: { path: "politica-de-privacidad" },
      pt: { path: "politica-de-privacidade" },
    },
  },
  {
    name: "licenses",
    ViewComponent: <LicensesViewWrapper />,
    visible: true,
    pathSets: {
      es: { path: "licencias" },
      pt: { path: "licencas" },
    },
  },
  {
    name: "login-link",
    ViewComponent: <LoginFormViewWrapper />,
    visible: true,
    pathSets: {
      es: { path: "inicio-de-sesion" },
      pt: { path: "conecte-se" },
    },
  },
  {
    name: "login-link-sent",
    ViewComponent: <LoginSuccessViewWrapper />,
    pathSets: {
      es: { path: "inicio-de-sesion/link" },
      pt: { path: "inicio-de-sesion/link" },
    },
  },
  {
    name: "login-expired",
    ViewComponent: <LoginExpiredViewWrapper />,
    pathSets: {
      es: { path: "inicio-de-sesion/caducado" },
      pt: { path: "inicio-de-sesion/caducado" },
    },
  },
  {
    name: "login-failed",
    ViewComponent: <LoginFailureViewWrapper />,
    pathSets: {
      es: { path: "inicio-de-sesion/fallido" },
      pt: { path: "inicio-de-sesion/fracassado" },
    },
  },
  {
    name: "login-account-exists",
    ViewComponent: <LoginExistsViewWrapper />,
    pathSets: {
      es: { path: "inicio-de-sesion/cuenta-existe" },
      pt: { path: "inicio-de-sesion/conta-existe" },
    },
  },
  {
    name: "link-login",
    ViewComponent: <LinkLoginViewWrapper />,
    pathSets: {
      es: { path: "link/login" },
      pt: { path: "link/login" },
    },
  },
  {
    name: "forgot-password",
    ViewComponent: <ForgotPasswordViewWrapper />,
    visible: true,
    pathSets: {
      // TODO: mitä tehdä näille? saisiko koodattua mobiiliin oikeat urlit
      es: { path: "frogot-password" },
      pt: { path: "forgot-password" },
    },
  },
  {
    name: "recover-password",
    ViewComponent: <RecoverPasswordViewWrapper />,
    visible: true,
    pathSets: {
      es: { path: "recuperacion-de-contrasena" },
      pt: { path: "recuperacao-de-senha" },
    },
  },
  {
    name: "app-dashboard",
    ViewComponent: <AppDashboardViewWrapper />,
    pathSets: {
      es: { path: "app/dashboard" },
      pt: { path: "app/dashboard" },
    },
  },
  {
    name: "app-exercise",
    ViewComponent: <AppExerciseViewWrapper />,
    pathSets: {
      es: { path: "app/exercise" },
      pt: { path: "app/exercise" },
    },
  },
  {
    name: "app-forgot-password",
    ViewComponent: <AppLinkViewWrapper />,
    pathSets: {
      es: { path: "app/forgot-password" },
      pt: { path: "app/forgot-password" },
    },
  },
  {
    name: "raffle",
    ViewComponent: <QuestionnaireRaffleViewWrapper />,
    pathSets: {
      es: { path: "habla-ingles" },
      pt: { path: "fale-ingles" },
    },
  },
  {
    name: "generate",
    ViewComponent: <QuestionnaireGenerateViewWrapper />,
    pathSets: {
      es: { path: "habla-ingles/genarate" },
      pt: { path: "fale-ingles/genarate" },
    },
  },
  {
    name: "reset",
    ViewComponent: <QuestionnaireResetViewWrapper />,
    pathSets: {
      es: { path: "habla-ingles/reset" },
      pt: { path: "fale-ingles/reset" },
    },
  },
  {
    name: "questionnaire",
    ViewComponent: <QuestionnaireVersionViewWrapper />,
    pathSets: {
      es: { path: "habla-ingles/:version" },
      pt: { path: "fale-ingles/:version" },
    },
  },
  {
    name: "question",
    ViewComponent: <QuestionnaireQuestionViewWrapper />,
    pathSets: {
      es: { path: "habla-ingles/:version/:question" },
      pt: { path: "fale-ingles/:version/:question" },
    },
  },
  {
    name: "questionnaire-update-email",
    ViewComponent: <QuestionnaireUpdateEmailViewWrapper />,
    pathSets: {
      es: { path: "habla-ingles/:version/actualizar-email" },
      pt: { path: "fale-ingles/:version/atualizar-email" },
    },
  },
  {
    name: "questionnaire-email-exists",
    ViewComponent: <QuestionnaireEmailExistsViewWrapper />,
    pathSets: {
      es: { path: "habla-ingles/:version/email-ya-existe" },
      pt: { path: "fale-ingles/:version/email-ja-existe" },
    },
  },
  {
    name: "questionnaire-start-level-test",
    ViewComponent: <QuestionnaireStartLevelTestViewWrapper />,
    pathSets: {
      es: { path: "habla-ingles/:version/empezar-test-de-nivel" },
      pt: { path: "fale-ingles/:version/teste-de-nivel-inicial" },
    },
  },
  {
    name: "questionnaire-congratulations",
    ViewComponent: <QuestionnaireCongratulationsViewWrapper />,
    pathSets: {
      es: { path: "habla-ingles/:version/enhorabuena" },
      pt: { path: "fale-ingles/:version/parabens" },
    },
  },
  {
    name: "questionnaire-thank-you",
    ViewComponent: <QuestionnaireThankYouViewWrapper />,
    pathSets: {
      es: { path: "habla-ingles/:version/gracias" },
      pt: { path: "fale-ingles/:version/obrigado" },
    },
  },
  {
    name: "questionnaire-new-confirm",
    ViewComponent: <QuestionnaireNewConfirmViewWrapper />,
    pathSets: {
      es: { path: "habla-ingles/:version/nueva-confirmacion" },
      pt: { path: "fale-ingles/:version/nova-confirmacao" },
    },
  },
  {
    name: "questionnaire-post-purchase",
    ViewComponent: <QuestionnairePostPurchaseViewWrapper />,
    pathSets: {
      es: { path: "habla-ingles/:version/post-purchase" },
      pt: { path: "fale-ingles/:version/post-purchase" },
    },
  },
  {
    name: "questionnaire-reactivate-or-new",
    ViewComponent: <QuestionnaireReactivateOrNewViewWrapper />,
    pathSets: {
      es: { path: "habla-ingles/:version/reactivar-o-nueva" },
      pt: { path: "fale-ingles/:version/reativar-ou-novo" },
    },
  },
  {
    name: "questionnaire-reactivate",
    ViewComponent: <QuestionnaireReactivateViewWrapper />,
    pathSets: {
      es: { path: "habla-ingles/:version/reactivar" },
      pt: { path: "fale-ingles/:version/reativar" },
    },
  },
  {
    name: "questionnaire-next-step",
    ViewComponent: <QuestionnaireNextStepViewWrapper />,
    pathSets: {
      es: { path: "habla-ingles/:version/siguiente-paso" },
      pt: { path: "fale-ingles/:version/proximo-passo" },
    },
  },
  {
    name: "questionnaire-initial-level-test",
    ViewComponent: <QuestionnaireInitialLevelTestViewWrapper />,
    pathSets: {
      es: { path: "habla-ingles/:version/test-de-nivel-inicial" },
      pt: { path: "fale-ingles/:version/teste-de-nivel-inicial" },
    },
  },
  {
    name: "questionnaire-login-with-password",
    ViewComponent: <QuestionnaireLoginWithPasswordViewWrapper />,
    pathSets: {
      es: { path: "habla-ingles/:version/usuario" },
      pt: { path: "fale-ingles/:version/usuario" },
    },
  },
  {
    name: "questionnaire-verify-email",
    ViewComponent: <QuestionnaireVerifyEmailViewWrapper />,
    pathSets: {
      es: { path: "habla-ingles/:version/verificar-email" },
      pt: { path: "fale-ingles/:version/verificar-email" },
    },
  },
  {
    name: "payment-failed",
    ViewComponent: <PaymentFailureViewWrapper />,
    pathSets: {
      es: { path: "pago/fallido" },
      pt: { path: "pagamento/fracassado" },
    },
  },
];

export default definititions;
