import { ConfigModel } from "models/config";
import { LocaleType } from "models/locale";
import { OptionModel } from "models/option";
import { QuestionModel } from "models/question";
import { QuestionnaireVersion } from "models/questionnaire";
import { StateModel } from "models/state";
import { SIGNUP_EMAIL_KEY, SIGNUP_NAME_KEY, UserModel } from "models/user";
import { read } from "./storage";
import { getControlQuestionnaireVersion } from "./questionnaire";

export const buildUser = (config: ConfigModel): UserModel => {
  const cachedEmail = read<string>(SIGNUP_EMAIL_KEY);
  const cachedName = read<string>(SIGNUP_NAME_KEY);

  return {
    uuid: (config && config.uuid) || undefined,
    email: cachedEmail || "",
    password: "",
    firstname: cachedName || "",
    uiLanguage: config.uiLanguage,
  };
};

// this always builds an empty plain state, the values should be populated somewhere else
export const buildState = (locale: LocaleType, config: ConfigModel) => {
  const state: StateModel = {
    id: getControlQuestionnaireVersion(locale),
    signupDone: false,
    finished: false,
    visitedQuestionIds: [],
    selectedOptions: [],
    currentQuestionId: 2,
    user: buildUser(config),
  };
  return state;
};

// makes sure the state does not contain newer answers than the current view
const syncAnswers = (state: StateModel, currentQuestionId: number) => {
  const { visitedQuestionIds, selectedOptions: options } = state;

  // first purge visitedQuestionIds
  const idIndex = visitedQuestionIds.indexOf(currentQuestionId);
  if (idIndex !== -1) {
    state.visitedQuestionIds = visitedQuestionIds.slice(0, idIndex);
  }

  // purge answers
  const index = options.findIndex((so) => so.questionId === currentQuestionId);
  if (index !== -1) {
    state.selectedOptions = options.slice(0, index);
  }
};

// makes sure the state is up to date, literally removes answers for not-yet-seen questions and updates a few references
export const syncState = (
  state: StateModel,
  version: QuestionnaireVersion,
  questionId: number
) => {
  // remove questions that are after this question
  syncAnswers(state, questionId);

  // make sure the state references to current question
  state.id = version;
  state.currentQuestionId = questionId;
  return state;
};

export const hasAnswer = (
  state: StateModel,
  questionId: number,
  optionId: number
) => {
  const { selectedOptions } = state;
  const answer = selectedOptions.find(
    (so) => so.questionId === questionId && so.optionId === optionId
  );
  return answer !== undefined;
};

export const storeAnswer = (
  currentQuestion: QuestionModel,
  selectedOption: OptionModel,
  state: StateModel,
  updateQuestionnaireState: (newState: StateModel) => void
) => {
  // if id equals zero, it might be omitted from json
  const { id = 0 } = selectedOption;

  // get state
  const { visitedQuestionIds, selectedOptions } = state;

  // make sure existing answers for the question does not exist
  if (!visitedQuestionIds.includes(currentQuestion.id)) {
    visitedQuestionIds.push(currentQuestion.id);
  }

  // store answered option
  const oldSelectedOption = selectedOptions.find(
    (opt) => opt.questionId === currentQuestion.id
  );

  if (oldSelectedOption) {
    // just update the optionId
    oldSelectedOption.optionId = id;
  } else {
    // create a new entry
    selectedOptions.push({
      questionId: currentQuestion.id,
      optionId: id,
    });
  }

  updateQuestionnaireState(state);
};

export const isSignupDone = (state: StateModel) => {
  return state.signupDone || false;
};

export const isStateValid = (state: StateModel) => {
  return state && !!state.visitedQuestionIds;
};
