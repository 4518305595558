import { createJSONStorage, devtools, persist } from "zustand/middleware";
import { GeoSlice, createGeoSlice } from "./geo";
import { create } from "zustand";
import { createIdsSlice, IdsSlice } from "./ids";

export const useStore = create<GeoSlice & IdsSlice>()(
  devtools(
    persist((...a) => ({ ...createGeoSlice(...a), ...createIdsSlice(...a) }), {
      name: "masterenglish",
      storage: createJSONStorage(() => localStorage),
    })
  )
);

export const resetStore = () => {
  // note: geo is not resetted
  useStore.setState((state) => ({ ...state, cid: undefined, vid: undefined }));
};
