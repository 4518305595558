import Loader from "components/loader";
import { useConfig } from "hooks/config";
import { useQuestionnaire } from "hooks/questionnaire";
import { useLink, useStaticLink } from "hooks/route";
import { getVersion } from "lib/questionnaire";
import { read, write } from "lib/storage";
import { PaymentStatus, PAYMENT_STATUS_KEY } from "models/payment";
import { StaticViewIdMap } from "models/question";
import { SKIP_TO_INSTALL_KEY } from "models/user";
import { FunctionComponent, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { sendVirtualQuestionnaireQuestionShownEvent } from "services/event";
import { fetchPaymentStatus } from "services/payment-status";

const QuestionnairePostPurchaseView: FunctionComponent = () => {
  const config = useConfig();
  const navigate = useNavigate();
  const questionnaire = useQuestionnaire();
  const [loading, toggleLoading] = useState(true);
  const [paymentStatus, togglePaymentStatus] = useState<
    PaymentStatus | undefined
  >(undefined);

  const version = getVersion(questionnaire);

  const redirectUrlThankYou = useStaticLink(version, "thank-you");
  const redirectUrlStartLevelTest = useStaticLink(version, "start-level-test");
  const paymentFailureLink = useLink("payment-failed");

  useEffect(() => {
    // if we are in PostPurchase, send event about it
    sendVirtualQuestionnaireQuestionShownEvent({
      uuid: config.uuid,
      questionId: StaticViewIdMap["post-purchase"],
    });
  }, []);

  useEffect(() => {
    const checkPaymentStatus = async () => {
      const cachedPaymentStatus = read<PaymentStatus>(PAYMENT_STATUS_KEY);

      let paymentStatus: PaymentStatus;

      if (cachedPaymentStatus) {
        // first use cached status
        paymentStatus = cachedPaymentStatus;
      } else {
        // try to check status from the backend
        paymentStatus = await fetchPaymentStatus("paddle");

        // if we don't have id yet, schedule a new check
        if (paymentStatus.status === "pending") {
          schedule();
          return;
        }
      }

      // toggle all states at once, though react will batch them anyway
      togglePaymentStatus(paymentStatus);
      toggleLoading(false);
    };

    // schedule a new payment status check
    const schedule = () => {
      setTimeout(() => {
        checkPaymentStatus();
      }, 1500);
    };

    // initial round
    schedule();
  }, []);

  // show nothing while loading
  if (loading || !paymentStatus) {
    return <Loader />;
  }

  // store some things for future views to use
  write(PAYMENT_STATUS_KEY, paymentStatus);

  // catch failured case
  if (paymentStatus.status === "cancelled") {
    navigate(paymentFailureLink);
    return null;
  }

  // all must be good
  navigate(
    read<boolean>(SKIP_TO_INSTALL_KEY) === true
      ? redirectUrlStartLevelTest
      : redirectUrlThankYou
  );
  return null;
};

export default QuestionnairePostPurchaseView;
