import { LocaleType } from "models/locale";
import { useLocation, useMatches } from "react-router";
import { useLocale } from "./geo";
import definititions, { RouteHandle, RouteNameType } from "routes/definitions";
import { QuestionnaireVersion } from "models/questionnaire";
import { StaticViewEnum } from "models/question";

export const useRoute = () => {
  const match = useRouteMatch();

  if (match) {
    const handle = match.handle as RouteHandle;
    if (handle) {
      const { name } = handle;

      // find from routes
      const route = definititions.find((def) => def.name === name);
      if (route) {
        return route;
      }
    }
  }
};

export const useRouteMatches = () => {
  const matches = useMatches();
  return matches.filter((m) => m.handle !== undefined);
};

export const useRouteMatch = () => {
  const location = useLocation();

  const matches = useRouteMatches();
  for (let i = 0; i < matches.length; i += 1) {
    if (matches[i].pathname === location.pathname) {
      return matches[i];
    }
  }
};

export const useRouteParams = () => {
  const match = useRouteMatch();
  return match?.params;
};

export const usePageLocale = (): LocaleType => {
  const match = useRouteMatch();
  const locale = useLocale();
  const location = useLocation();

  // special case as we can't reason the locale from the url
  if (location.pathname === "/") {
    return locale;
  }

  // if we have a route match, dig locale from there
  if (match) {
    const handle = match.handle as RouteHandle;
    if (handle) {
      const { locale } = handle;
      return locale as LocaleType;
    }
  }

  // use default from geo request
  return locale;
};

export const useLink = (
  routeName: RouteNameType,
  params?: Record<string, string>
) => {
  const locale = usePageLocale();

  let link = `/${locale}`;

  // find the correct route
  const route = definititions.find((def) => def.name === routeName);
  if (route) {
    const { pathSets } = route;
    const set = pathSets[locale];
    if (set) {
      link = `/${locale}/${set.path}`;
    }
  }

  // replace params in the link
  for (const key in params) {
    link = link.replace(`:${key}`, params[key]);
  }

  return link;
};

export const useQuestionnaireLink = (version: QuestionnaireVersion) => {
  const link = useLink("questionnaire", { version });
  return link;
};

export const useQuestionLink = (
  version: QuestionnaireVersion,
  question: number
) => {
  const link = useLink("question", { version, question: question.toString() });
  return link;
};

export const useStaticLink = (
  version: QuestionnaireVersion,
  staticView: StaticViewEnum
) => {
  const link = useLink(`questionnaire-${staticView}`, { version });
  return link;
};

export const useRaffleLink = () => {
  const link = useLink("raffle");
  return link;
};

export const useStudyLanguage = () => "en-US";

export const useUILanguage = () => {
  const locale = usePageLocale();
  if (locale === "pt") {
    return "pt-BR";
  }
  return "es-419";
};
