import Input from "components/form/input";
import View from "components/layout/view";
import Navbar from "components/navigation/navbar";
import Title from "components/title";
import { FunctionComponent, useEffect, useState } from "react";
import { read, write } from "lib/storage";
import { login } from "services/user";
import { useTranslations } from "hooks/translations";
import ContentContainer from "components/layout/content";
import ActionContainer, { ActionType } from "components/layout/actions";
import {
  emailValidator,
  passwordValidator,
  requiredValidator,
  runValidations,
  ValidatorFn,
} from "lib/validators";
import { REFRESH_TOKEN, SIGNUP_EMAIL_KEY, TOKEN } from "models/user";
import {
  parseEntitlementExists,
  parseQuestionnaireCompleted,
  parseQuestionnaireVersion,
  restore,
} from "lib/restore";
import { useConfig, useUpdateConfig } from "hooks/config";
import { useQuestionnaire, useUpdateQuestionnaire } from "hooks/questionnaire";
import {
  useQuestionnaireState,
  useUpdateQuestionnaireState,
} from "hooks/state";
import { gtmUserId } from "lib/gtm";
import { STATE_KEY } from "models/questionnaire";
import { loadSavedQuestionnaire } from "services/questionnaire";
import { isStateValid } from "lib/state";
import { getLastSignupView, getVersion } from "lib/questionnaire";
import { useNavigate } from "react-router";
import { useLocale } from "hooks/geo";
import { useLink, useQuestionLink, useRaffleLink } from "hooks/route";

const QuestionnaireLoginWithPasswordPage: FunctionComponent = () => {
  const config = useConfig();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const t = useTranslations();
  const state = useQuestionnaireState();
  const updateConfig = useUpdateConfig();
  const questionnaire = useQuestionnaire();
  const updateQuestionnaire = useUpdateQuestionnaire();
  const updateQuestionnaireState = useUpdateQuestionnaireState();
  const [loaded, toggleLoaded] = useState(false);
  const fpHref = useLink("recover-password");
  const locale = useLocale();
  const version = getVersion(questionnaire);
  const lastSignupView = getLastSignupView(questionnaire);
  const signupLink = useQuestionLink(version, lastSignupView.id);
  const subLink = useLink("handle-your-sub");
  const baseLink = useRaffleLink();
  const loginSuccessLink = useLink("login-link-sent");

  useEffect(() => {
    // check if we have email in the cache
    const cachedEmail = read<string>(SIGNUP_EMAIL_KEY);
    if (cachedEmail) {
      setEmail(cachedEmail);
    }
    toggleLoaded(true);
  }, []);

  const handleEmailChange = (email: string) => {
    setEmail(email);
    write(SIGNUP_EMAIL_KEY, email);
  };

  const handlePasswordChange = (password: string) => setPassword(password);

  const handleSubmit = async () => {
    // first do login
    try {
      const loginResponse = await login(email, password || "");
      const { token, refreshToken, user } = loginResponse;
      write(TOKEN, token);
      write(REFRESH_TOKEN, refreshToken);

      // overwrite config a bit
      console.info(`Replace config.uuid with UUID = ${user.uuid}`);
      config.uuid = user.uuid || "";
      config.questionnaireVersion = parseQuestionnaireVersion(loginResponse);
      config.completed = parseQuestionnaireCompleted(loginResponse);
      config.entitlementExists = parseEntitlementExists(loginResponse);
      updateConfig(config);

      // update state as well
      state.user.uuid = user.uuid;
      state.signupDone = true;
      write(STATE_KEY, state);

      // send uuid to GTM
      gtmUserId(config.uuid);
    } catch (err) {
      navigate(fpHref);
      return;
    }

    // fetch entitlements - please check entitlement source
    const response = await loadSavedQuestionnaire(config, locale);
    const isOldStateInvalid = !isStateValid(response.state);

    // this is a special case for login-with-password as the user has already started a questionnaire, but the state received is invalid.
    // if the conditions are met, this allows user to continue the questionnaire.
    if (isOldStateInvalid && config.entitlementExists === false) {
      // redirect user to the "Name" view so we can gather valid data.
      navigate(signupLink);
      return; // exit and do not go to restore
    }

    // then put the user to the restore process
    // NOTE: this will make a redirect
    await restore({
      locale,
      config,
      navigate,
      updateQuestionnaire,
      updateQuestionnaireState,
      baseLink,
      subLink,
    });
  };

  const emailValidators: ValidatorFn[] = [
    requiredValidator(t),
    emailValidator(t),
  ];

  const passwordValidators: ValidatorFn[] = [
    requiredValidator(t),
    passwordValidator(t),
  ];

  const handleForgotPassword = () => {
    navigate(fpHref);
  };

  const actions: ActionType[] = [
    {
      type: "button",
      button: {
        text: t("login_button_start"),
        disabled:
          runValidations(email, [...emailValidators]) !== undefined ||
          runValidations(password, [...passwordValidators]) !== undefined,
        onClick: handleSubmit,
        type: "primary",
        href: loginSuccessLink,
      },
    },
    {
      type: "button",
      button: {
        text: t("login_button_help"),
        onClick: handleForgotPassword,
        href: fpHref,
        type: "link",
      },
    },
  ];

  if (!loaded) {
    return null;
  }

  return (
    <View title={t("login_title")} description="" footer showLogoInFooter cover>
      <Navbar logoAsLink={false} />
      <ContentContainer>
        <Title>{t("login_title")}</Title>
        <Input
          type="email"
          name="email"
          inputMode="email"
          autoFocus
          value={email}
          enterKeyHint="go"
          validators={emailValidators}
          label={t("label_email")}
          placeholder={t("label_email")}
          onChange={handleEmailChange}
          autoComplete="username"
        />
        <Input
          type="password"
          name="password"
          inputMode="text"
          autoFocus
          value={password}
          enterKeyHint="go"
          validators={passwordValidators}
          label={t("label_password")}
          placeholder={t("label_password")}
          onChange={handlePasswordChange}
          onSubmit={handleSubmit}
        />
      </ContentContainer>
      <ActionContainer actions={actions} />
    </View>
  );
};

export default QuestionnaireLoginWithPasswordPage;
