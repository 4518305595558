import { saveAs } from "file-saver";
import definititions from "./definitions";
import { LocaleType } from "models/locale";

export const downloadRobotsTXT = () => {
  // gather a list of urls to block
  const disallowList: string[] = [];
  definititions.forEach((route) => {
    const { visible, pathSets } = route;

    if (!visible) {
      for (const uncastedLocale in pathSets) {
        const locale = uncastedLocale as LocaleType;
        const set = pathSets[locale];
        if (set) {
          let pathToDisallow = set.path;

          // catch-all route is not accepted here
          if (pathToDisallow === "*") {
            return;
          }

          // if there are params, just add the first part
          if (pathToDisallow.includes("/:")) {
            pathToDisallow = pathToDisallow.split("/:")[0];
          }

          // if a shorter version already exists, do not include
          if (
            disallowList.filter((path) => {
              if (path === "") {
                return false;
              }
              return pathToDisallow.includes(path);
            }).length > 0
          ) {
            return;
          }

          // accepted, push
          disallowList.push(pathToDisallow);
        }
      }
    }
  });

  // file contents
  const contents = [
    "User-agent: *\n",
    ...disallowList.map((path) => `Disallow: /${path}\n`),
    `Sitemap: ${import.meta.env.VITE_PUBLIC_ME_URL}sitemap.xml`,
  ];

  // save to file
  saveAs(
    new Blob(contents, {
      type: "text/plain;charset=utf-8",
    }),
    "robots.txt"
  );
};
