import { FunctionComponent } from "react";
import View from "components/layout/view";
import { useTranslations } from "hooks/translations";
import Navbar from "components/navigation/navbar";
import Fade from "components/fade";
import { StyledContentContainer } from "../view.styles";
import TermsOfUseContent from "./content";

const TermsOfUseView: FunctionComponent = () => {
  const t = useTranslations();

  return (
    <View
      view="dark"
      title={t("terms_of_use")}
      description={t("meta_description_terms_page")}
      footer
      showLogoInFooter
    >
      <Navbar links />
      <Fade />
      <StyledContentContainer>
        <TermsOfUseContent />
      </StyledContentContainer>
    </View>
  );
};

export default TermsOfUseView;
