import { createContext, FunctionComponent, useEffect, useState } from "react";
import { TranslationModel } from "models/locale";

import es from "translations/es";
import pt from "translations/pt";

import { useLocale } from "hooks/geo";

// create context
export const TranslationContext = createContext<TranslationModel>(es);

const TranslationProvider: FunctionComponent<any> = ({ children }) => {
  // defaults
  const [translations, toggleTranslations] = useState<TranslationModel>(es);
  const locale = useLocale();

  useEffect(() => {
    switch (locale) {
      case "es":
        toggleTranslations(es);
        break;
      case "pt":
        toggleTranslations(pt);
        break;
    }
  }, [locale]);

  return (
    <TranslationContext.Provider value={translations}>
      {children}
    </TranslationContext.Provider>
  );
};

export default TranslationProvider;
