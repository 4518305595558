import { TranslationModel } from "models/locale";

const ptTranslations: TranslationModel = {
  link_contact_us: "https://masterenglish.zendesk.com/hc/pt-br",
  link_refund:
    "https://masterenglish.zendesk.com/hc/pt-br/articles/10485682004626--Como-posso-obter-um-reembolso-",

  loading: "Carregando",

  validator_at_least_8: "8 ou mais caracteres",
  validator_invalid_email: "Insira um endereço de e-mail válido",
  validator_required: "Este campo é obrigatório",
  validator_phone: "Digite um número válido com o prefixo",

  old_os_text1:
    "O aplicativo Master English suporta os seguintes sistemas operacionais:",
  old_os_text2:
    "iOS: versão 14.0 ou posterior\nAndroid: versão 11.0 ou posterior",
  old_os_text3:
    "Por favor, atualize seu sistema operacional antes de continuar.",
  old_os_title: "Seu sistema operacional não é compatível",
  old_os_button: "Aceitar",

  footer_menu_help: "Ajuda",
  footer_menu_product: "Produto",
  footer_menu_social: "Redes Sociais",
  footer_contact_us: "Contate-nos",
  footer_main_title: "Programa Master English",
  footer_privacy_policy: "Política de Privacidade",
  footer_terms: "Termos de Uso",
  footer_manage_plan: "Gerencie sua assinatura",
  footer_refund: "Política de reembolso",
  label_email: "E-mail",
  label_firstname: "Nome",
  label_password: "Senha",

  title_404: "Erro 404",
  text_404: "Não foi possível encontrar a página.",
  link_404: "Tente ir para masterenglish.com.",

  terms_of_use: "Termos de Uso",

  account_exists_body:
    "Se você esqueceu sua senha, insira seu e-mail e clique em Continuar. Nós enviaremos um link para você iniciar sessão.",

  account_exists_button: "Continuar",
  account_exists_title: "Esta conta já existe",
  calculating: "Calculando",
  check_email_button1: "Sim",
  check_email_button2: "Não, quero modificar",
  check_email_p1: "Você precisará dele para iniciar sessão no seu programa.",
  check_email_title: "Está correto?",
  congratulations_button1: "Estou mais do que pronto!",
  congratulations_button2: "Estou pronto!",
  congratulations_text:
    "Acreditamos que você tomou uma excelente decisão ao querer investir no seu aprendizado de inglês.<br /><br />Não é apenas sobre aprender um idioma, é investir em você e no seu futuro.<br /><br />Temos tudo pronto para começar o seu aprendizado e levar você até um nível avançado de inglês.<br /><br />Você está pronto para começar?",
  congratulations_title: "Parabéns!",

  dayselection_done: "Pronto! Agora você pode continuar.",
  dayselection_in_progres: "Continue após selecionar ##DAYS## dias.",
  email_error_body: "Endereço de e-mail #EMAIL# não encontrado.",
  email_error_button1: "Tente novamente",
  email_error_button2: "Criar uma conta",
  email_error_title: "Não encontrado!",
  email_exists_button1: "Tente novamente",
  email_exists_p1:
    "O endereço de e-mail #EMAIL# já está reservado para outra conta.",
  email_exists_title: "Este e-mail já existe",
  follow_steps_p3: "Faça login com #EMAIL# e a senha que você acabou de criar.",
  follow_steps_p4: "Complete o teste de nível inicial.",
  follow_steps_title: "Siga estes passos",
  footer_fb_alt: "Link do Facebook",
  footer_ig_alt: "Link do Instagram",
  hint_agree_on_terms_and_privacy:
    "Ao continuar, você aceita nossos #LINK_TOS# e #PRIVACY_LINK#.",
  level_test_button: "Próximo",
  level_test_text:
    "Para garantir uma conexão de internet e uso do microfone otimizados, você completará seu teste de nível inicial por meio do nosso aplicativo móvel.<br /><br />Após completá-lo, você obterá seu programa personalizado de inglês.",
  level_test_title: "Teste de nível inicial",
  link_sent_body:
    "Enviamos um link para iniciar sessão para #EMAIL#. Clique no link que aparecerá no seu e-mail.",
  link_sent_title: "Link enviado",
  login_body:
    "Insira seu e-mail e clique em Continuar. Nós enviaremos um link para você iniciar sessão.",
  login_button: "Continuar",
  login_button_page_body: "Se você não tem o app Master English, baixe o app.",
  login_button_page_button: "Iniciar sessão",
  login_button_page_link: "baixe o app",
  login_button_page_title: "Iniciar sessão no app",
  login_link_expired_body:
    "Seu link expirou. Por favor, clique em Continuar para obter um novo link.",
  login_link_expired_button: "Continuar",
  login_link_expired_title: "Link expirado",
  login_title: "Iniciar sesión",
  login_title_forgot: "¿Olvidaste tu contraseña?",
  login_divider: "Todavía no tengo una cuenta",
  login_button_create_account: "Crear cuenta",
  login_button_start: "Iniciar sesión",
  login_button_help: "¿Necesitas ayuda?",

  confirm_title: "Confirmación",
  confirm_desc:
    "Estás a punto de reiniciar tu progreso acumulado en Master English para comenzar un nuevo programa. ¿Es esta tu intención?",
  confirm_button_yes:
    "Sí, quiero reiniciar mi progreso y comenzar un nuevo programa",
  confirm_button_no: "No, no quiero reiniciar mi progreso",

  handle_subcription_title: "Gestiona tu suscripción",
  handle_subcription_description:
    "Gestiona tu suscripción al programa Master English en esta página.",
  handle_subcription_p1:
    "Empieza una suscripción para acceder a tu programa Master English. Una vez iniciada tu suscripción solo tendrás que descargar nuestra app Master English en tu dispositivo móvil iPhone o Android e iniciar sesión.",
  handle_subcription_p2:
    'Si quieres lograr un nivel avanzado de inglés con Master English pero todavía no tienes tu programa, <a href="#LINK#">empieza aquí</a>.',
  handle_subcription_cancel_title: "Cómo cancelar tu suscripción",
  handle_subcription_cancel_p1:
    "Tu suscripción se renueva automáticamente hasta que la canceles.",
  handle_subcription_cancel_p2:
    "Puedes cancelar tu suscripción en cualquier momento siguiendo estos pasos:",
  handle_subcription_cancel_item1:
    'Abre la <a href="#LINK#">app Master English</a> y ve al menú de la izquierda',
  handle_subcription_cancel_item2:
    'Selecciona <span style="font-weight: 600; color: #050505;">Configuración</span>',
  handle_subcription_cancel_item3:
    "Ve a <span style='font-weight: 600; color: #050505;'>Administrar suscripción</span>",
  handle_subcription_initial_text1:
    "Si iniciaste tu suscripción desde nuestro <strong>sitio web:</strong>",
  handle_subcription_initial_item1:
    "Haz clic en el botón <span style='font-weight: 600; color: #050505;'>Administrar suscripción</span>",
  handle_subcription_initial_item2:
    "Serás dirigido a nuestro sitio web donde podrás <span style='font-weight: 600; color: #050505;'>cancelar</span> tu suscripción",
  handle_subcription_initial_text2:
    "Si iniciaste tu suscripción dentro de la <strong>app Master English:</strong>",
  handle_subcription_initial_item3:
    "Serás dirigido al menú <span style='font-weight: 600; color: #050505;'>Suscripciones</span> de tu <span style='font-weight: 600; color: #050505;'>Apple App Store</span> o <span style='font-weight: 600; color: #050505;'>Google Play Store</span> desde donde podrás cancelar tu suscripción",
  handle_subcription_refund:
    "Si no estás satisfecho con tu compra, contáctanos a <a href='mailto:soporte@masterenglish.com'>soporte@masterenglish.com</a> dentro de los 30 días posteriores a tu cargo de suscripción y te haremos un reembolso.",
  handle_subcription_help_title: "¿Necesitas ayuda?",
  handle_subcription_help_text:
    "Si tienes dudas o necesitas ayuda para cancelar tu suscripción, envíanos un correo a <a href='mailto:soporte@masterenglish.com'>soporte@masterenglish.com</a>y estaremos encantados de ayudarte. Responderemos a tu mensaje en un plazo de 2 días.",

  reactivate_title: "Reactiva tu programa de inglés",
  reactivate_objectives:
    "<span style='color: white; font-weight: 600;'>Objetivos:</span>",
  reactivate_objective:
    "Conseguir <span style='color: #007AFF;'>un nivel avanzado</span> de inglés",
  reactivate_text:
    "Una vez que hayas reactivado tu programa, podrás ver en la aplicación móvil cuántas semanas de práctica te quedan para alcanzar un nivel avanzado.",
  reactivate_list_title_1: "Áreas de aprendizaje de inglés:",
  reactivate_list_title_2: "Cómo funciona:",
  reactivate_list_text_1: "Speaking, Listening, Reading, Writing y Grammar",
  reactivate_list_text_2:
    "Hemos guardado tu progreso anterior. Tu programa continuará desde donde lo dejaste. Después de que hayas reactivado tu programa, podrás iniciar sesión en la aplicación Master English con tu iPhone o teléfono Android. Nuestras lecciones personalizadas te permitirán ponerte al día rápidamente.",
  reactivate_payment_type:
    "Tu suscripción se renovará mensualmente hasta que la canceles. Puedes cancelar tu suscripción y tu programa en cualquier momento.\n\nTe enviaremos un email 3 días antes de que se cumplan los ${months} meses que dura tu programa para que te acuerdes de cancelar tu suscripción antes de que se renueve el #MONTHS# º mes, si así lo deseas.",
  reactivate_payment_description:
    "Sin compromiso. Cancela en cualquier momento.",
  reactivate_payment_header:
    "Selecciona un método de pago para reactivar tu programa",
  reactivate_payment_title:
    "Inicia una suscripción mensual para reactivar tu programa",
  reactivate_payment_footer:
    "Puedes cancelar tu suscripción y tu programa en cualquier momento. Para ello, envíanos un email a soporte@masterenglish.com.",
  reactivate_reviews: "Nuestra valoración en la App Store es de 4.9/5.",

  reactivate_or_new_title: "Programa pausado",
  reactivate_or_new_desc1: "Tu programa Master English está en pausa.",
  reactivate_or_new_desc2: "¿Qué te gustaría hacer?",
  reactivate_or_new_button_reactivate:
    "Reactivar mi programa existente y continuar desde donde lo dejé",
  reactivate_or_new_button_new: "Quiero empezar un nuevo programa",

  menu_item1: "Comece hoje",
  menu_item2: "Iniciar sessão",
  menu_item3: "Gerencie seu plano",
  meta_description_front_page:
    "Master English leva você do nível básico ou intermediário para falar inglês com fluência profissional total. Sinta-se seguro em qualquer conversa. Comece hoje!",
  meta_description_licenses_page:
    "O seguinte software de código aberto e bibliotecas de terceiros participaram na criação deste aplicativo.",
  meta_description_privacy_page:
    "Sua privacidade é importante. Nossa Política de Privacidade inclui como coletamos, usamos, divulgamos, transmitimos e armazenamos suas informações pessoais.",
  meta_description_purchase_error_page:
    "Não foi possível processar sua transação. Por favor, tente novamente.",
  meta_description_terms_page:
    "Master English/WordDive S.L. oferece serviços e produtos aos seus usuários, como o programa Master English, de acordo com os presentes Termos de Uso.",
  meta_description_thank_you_page:
    "Obrigado por escolher Master English. Baixe o aplicativo e comece a aprender.",
  months: "meses",
  next_up_button: "Entendido",
  next_up_text:
    "Graças às suas respostas ao nosso questionário, já temos uma ideia clara do seu nível de inglês.<br /><br />A seguir, pediremos que você complete um <span style='color: #007aff;'>Teste de nível inicial</span> para confirmar seu nível de forma precisa.<br /><br />Com base nos seus resultados, faremos os últimos ajustes no seu programa.",
  next_up_title: "Próximo passo",
  page_title_front_page:
    "Fale inglês com fluência profissional | Seu programa online",
  page_title_licenses: "Licenças",
  page_title_privacy: "Política de Privacidade",
  page_title_purchase_error: "Algo deu errado",
  post_purchase_button1: "Verificar e-mail",
  post_purchase_p1: "A seguir, vamos verificar se seu e-mail está correto.",
  post_purchase_title: "Criamos seu programa!",
  purchase_error_button: "Tentar novamente",
  purchase_error_p1: "Não foi possível processar sua transação.",
  purchase_error_title: "Ops! Algo deu errado",
  purchase_loader: "Preparando opção de pagamento",
  section_1_button: "Comece hoje",
  section_1_description_p1_embedded:
    "Master English é um aplicativo projetado para falantes nativos de espanhol que desejam alcançar um nível avançado de inglês.",
  section_1_description_p2_embedded:
    "Ao completar o programa Master English, você será capaz de manter conversas em inglês com confiança e se candidatar a vagas de emprego onde o inglês é um requisito.",
  section_1_title:
    "Aprenda <br responsive mobile />a falar inglês <br />com fluência",
  section_2_description_p1_embedded:
    "Nos especializamos em ajudar adultos de língua espanhola que desejam alcançar um nível avançado de inglês e se destacar em seu trabalho.",
  section_2_description_p2_embedded:
    "Não importa se seu nível atual é iniciante, básico, intermediário ou avançado; nós te ajudaremos a alcançar um nível avançado da maneira mais eficaz possível.",
  section_2_description_p3_embedded:
    "Além disso, você não estará limitado por horários nem locais. Com base no seu nível de inglês e necessidades, daremos a você um programa personalizado que você pode completar de forma independente online com o aplicativo Master English.",
  section_2_title: "Para quem é o Master English?",
  section_3_description_bullet_1: "5 aulas de 30 min por semana",
  section_3_description_bullet_1_bold: "Horário 1:",
  section_3_description_bullet_2_bold: "Horário 2:",
  section_3_description_bullet_2_method: "3 aulas de 50 min por semana",
  section_3_description_bullet_3: "5 aulas de 60 min por semana",
  section_3_description_bullet_3_bold: "Horário intensivo:",
  section_3_description_p1_embedded:
    "Primeiro, pediremos que você responda a um breve questionário para conhecer seu nível de inglês e suas áreas de dificuldade.",
  section_3_description_p2_embedded:
    "Então, você poderá escolher o horário que melhor se adapte a você:",
  section_3_description_p3_embedded:
    "A partir daí, criaremos um programa personalizado e lhe diremos quanto tempo levará para alcançar um nível avançado de inglês. No geral, levará entre 6 e 12 meses, dependendo do seu nível inicial e ritmo de aprendizado.",
  section_3_description_p4_embedded:
    "Uma vez criado seu programa, você poderá acessá-lo através do aplicativo Master English. À medida que você avança e pratica com o app, conheceremos melhor seu ritmo de aprendizado. Isso nos permitirá otimizar e personalizar ainda mais suas lições para ajudá-lo a melhorar seu inglês o mais rápido possível.",
  section_3_title: "Como funciona o programa Master English?",
  section_4_description_p1_embedded:
    "Nosso programa foi desenvolvido a partir de um estudo exaustivo e análise dos problemas mais comuns enfrentados por falantes nativos de espanhol ao aprender inglês.",
  section_4_description_p2_embedded:
    "Nossa abordagem de aprendizado e nossas lições são projetadas por uma equipe premiada de especialistas em inglês. Além disso, todos os nossos professores possuem mestrados em pedagogia e linguística.",
  section_4_description_p3_embedded:
    "Contamos com uma equipe de falantes nativos de inglês que nos fornecem modelos para nossos exercícios de conversação e compreensão auditiva.",
  section_4_description_p4_embedded:
    "Nossa tecnologia de aprendizado, patenteada nos Estados Unidos, detecta sua velocidade de aprendizagem e áreas de dificuldade. Com essa informação, personaliza suas lições para garantir resultados permanentes.",
  section_4_title: "Por que o Master English é tão eficaz?",
  section_5_description_p1_embedded:
    "Independentemente de onde você vive, falar bem em inglês é indispensável no mundo profissional.",
  section_5_description_p2_embedded:
    "Constantemente vemos como pessoas talentosas e capazes perdem oportunidades de trabalho porque seu inglês não é suficientemente bom.",
  section_5_description_p3_embedded:
    "É por isso que, depois de ver que isso se repetia ano após ano, decidimos tomar uma atitude. Reunimos uma equipe de 45 especialistas altamente qualificados no ensino de inglês, desenvolvimento de software, design UX, desenho e inteligência artificial, e criamos o programa online Master English.",
  section_5_description_p4_embedded:
    "Nosso objetivo era, e continua sendo, oferecer uma solução eficaz para aquelas pessoas interessadas em obter uma fluência profissional em inglês, e online.",
  section_5_description_p5_embedded:
    "Esperamos que com nosso programa você consiga tornar seus sonhos realidade.",
  section_5_title: "Por que criamos o Master English?",
  section_6_button: "Comece hoje",
  section_6_description_p1_embedded:
    "Provavelmente, você passou muito tempo lutando com seu inglês, pensando em como melhorá-lo e testando diferentes métodos de aprendizado que não levaram a lugar nenhum. É por isso que você está aqui, certo? Bem, não queremos que você perca mais oportunidades por não ter o nível de inglês de que precisa.",
  section_6_description_p2_embedded:
    "De agora em diante, queremos que você foque toda sua energia em melhorar seu inglês sistematicamente, semana após semana, para que, ao completar o programa, tenha centenas de oportunidades para escolher.",
  section_6_description_p3_embedded:
    "Comece hoje seu caminho para um nível avançado de inglês com o aplicativo Master English. É uma decisão da qual você nunca se arrependerá. Te vejo do outro lado!",
  section_6_title_v28: "Crie seu programa hoje mesmo",
  subscription_email_link: "suporte@masterenglish.com",
  thank_you_button: "Baixar Master English",
  thank_you_p2: "Clique no botão abaixo para baixar o app Master English.",
  thank_you_p3: "Abra o app e clique em “Iniciar sessão” (veja a imagem).",
  update_email_button1: "Atualizar",
  update_email_p1: "Certifique-se de escrever seu e-mail corretamente.",
  update_email_title: "Atualize seu e-mail",
};

export default ptTranslations;
