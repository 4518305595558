import { Fragment, FunctionComponent, lazy, Suspense, useState } from "react";
import Logo from "./logo";
import Link from "./link";
import Collapse from "./collapse";
import Toggle from "./toggle";
import { useTranslations } from "hooks/translations";
import { StyledNavbar } from "./navbar.styles";
import {
  createEmbeddedQuestionnaireState,
  getEmbeddedQuestionnaireVersion,
} from "lib/questionnaire";
import { useTheme } from "hooks/theme";
import { useNavigate, useSearchParams } from "react-router";
import { EmbeddedQuestionnaireFollowUpQuestion } from "models/questionnaire";
import { useLocale } from "hooks/geo";
import { useLink, useQuestionLink, useUILanguage } from "hooks/route";

const LazyProgress = lazy(() => import("./progress"));

export interface NavProps {
  className?: string;
  logo?: boolean;
  logoAsLink?: boolean;
  links?: boolean;
  progress?: boolean;

  // this one overrides the style from
  transparent?: boolean;
}

const Navbar: FunctionComponent<NavProps> = ({
  className,
  logo = true,
  logoAsLink = true,
  links = false,
  progress = false,
  transparent = false,
}) => {
  const t = useTranslations();
  const theme = useTheme();
  const [collapsed, toggleCollapsed] = useState(true);
  const [searchParams] = useSearchParams();
  const locale = useLocale();
  const uiLanguage = useUILanguage();
  const navigate = useNavigate();

  const questionnaireLink = useQuestionLink(
    getEmbeddedQuestionnaireVersion(locale),
    EmbeddedQuestionnaireFollowUpQuestion
  );
  const subLink = useLink("handle-your-sub");
  const loginLink = useLink("login-link");

  // hide logo, if ?hf=1 is passed
  if (searchParams.get("hf")) {
    return null;
  }

  const handleClick = () => {
    createEmbeddedQuestionnaireState(locale, uiLanguage, undefined, undefined);
    navigate(questionnaireLink);
  };

  return (
    <StyledNavbar
      className={className}
      $transparent={transparent}
      $theme={theme}
    >
      {logo && <Logo link={logoAsLink} />}
      {progress && (
        <Suspense fallback={<div />}>
          <LazyProgress />
        </Suspense>
      )}
      {links && (
        <Fragment>
          <Collapse collapsed={collapsed}>
            <Link href={questionnaireLink} onClick={() => handleClick()}>
              {t("menu_item1")}
            </Link>
            <Link href={loginLink}>{t("menu_item2")}</Link>
            <Link href={subLink} desktop={false}>
              {t("menu_item3")}
            </Link>
          </Collapse>
          <Toggle collapsed={collapsed} onToggle={toggleCollapsed} />
        </Fragment>
      )}
    </StyledNavbar>
  );
};

export default Navbar;
