import Input from "components/form/input";
import dayjs from "dayjs";
import {
  useQuestionnaireState,
  useUpdateQuestionnaireState,
} from "hooks/state";
import { useTranslations } from "hooks/translations";
import { isLocal } from "lib/env";
import { read, write } from "lib/storage";
import {
  emailValidator,
  requiredValidator,
  runValidations,
  ValidatorFn,
} from "lib/validators";
import { SignupEmailContentType } from "models/content/signup-email";
import { SIGNUP_EMAIL_KEY } from "models/user";
import { Fragment, FunctionComponent, useState } from "react";
import { ContentSchema, ContentProps } from "../factory";
import { ValidatorMethod } from "../../validations";
import { generateRandomName } from "./signup-name";

interface SignupEmailContentProps extends ContentProps {
  content: SignupEmailContentType;
  onSubmit: () => void;
}

export const generateRandomEmail = () => {
  const email = `${generateRandomName().toLowerCase()}+${dayjs().valueOf()}@worddive.com`;
  return email;
};

const SignupEmailContent: FunctionComponent<SignupEmailContentProps> = ({
  onChange,
  onSubmit,
}) => {
  const t = useTranslations();
  const state = useQuestionnaireState();
  const updateQuestionnaireState = useUpdateQuestionnaireState();

  const signupEmail = read<string>(SIGNUP_EMAIL_KEY);
  const originalEmail = state.user.email || signupEmail || "";
  const [email, setEmail] = useState(originalEmail);

  const randomise = () => {
    // create a randon email and change state, only works in local setup
    const email = generateRandomEmail();
    handleEmailChange(email);
  };

  const handleEmailChange = (email: string) => {
    // internal state
    setEmail(email);

    // store new value to the state
    state.user.email = email;
    updateQuestionnaireState(state);
    write(SIGNUP_EMAIL_KEY, email);

    // signal question level
    onChange();
  };

  const handleSubmit = () => {
    onSubmit();
  };

  const validators: ValidatorFn[] = [requiredValidator(t), emailValidator(t)];

  return (
    <Fragment>
      {isLocal() && (
        <div onClick={randomise}>[Click here to generate email]</div>
      )}
      <Input
        unique
        type="email"
        inputMode="email"
        autoFocus
        value={email}
        enterKeyHint="go"
        validators={validators}
        placeholder={t("label_email")}
        onChange={handleEmailChange}
        onSubmit={handleSubmit}
        autoCapitalize="words"
        autoComplete="username"
      />
    </Fragment>
  );
};

export default SignupEmailContent;

export const signupEmailContentValidator: ValidatorMethod = (state, t) => {
  const { user } = state;
  const { email } = user;

  // create validation
  const validators: ValidatorFn[] = [requiredValidator(t), emailValidator(t)];
  const validationResult = runValidations(email, validators);

  if (validationResult) {
    return "fail";
  }

  return "pass";
};

export const schema: ContentSchema = {
  Component: SignupEmailContent,
  validator: signupEmailContentValidator,
};
