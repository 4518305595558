import { Fragment, FunctionComponent, useEffect } from "react";
import View from "components/layout/view";
import Title from "components/title";
import Description from "components/description";
import { useTranslations } from "hooks/translations";
import ContentContainer from "components/layout/content";
import Navbar from "components/navigation/navbar";
import ActionContainer, { ActionType } from "components/layout/actions";
import { sendVirtualQuestionnaireQuestionShownEvent } from "services/event";
import { useConfig } from "hooks/config";
import { getVersion } from "lib/questionnaire";
import { useQuestionnaire } from "hooks/questionnaire";
import { useNavigate } from "react-router";
import { StaticViewIdMap } from "models/question";
import { useRaffleLink, useStaticLink } from "hooks/route";

const QuestionnaireNewConfirmView: FunctionComponent = () => {
  const t = useTranslations();
  const questionnaire = useQuestionnaire();
  const config = useConfig();
  const navigate = useNavigate();
  const raffleHref = useRaffleLink();

  const href = useStaticLink(getVersion(questionnaire), "reactivate-or-new");

  useEffect(() => {
    // send fixed event
    sendVirtualQuestionnaireQuestionShownEvent({
      uuid: config.uuid,
      questionId: StaticViewIdMap["new-confirm"],
    });
  }, []);

  const handleYes = () => {
    navigate(raffleHref);
  };

  const handleNo = () => {
    navigate(href);
  };

  const actions: ActionType[] = [
    {
      type: "button",
      button: {
        text: t("confirm_button_yes"),
        type: "default",
        onClick: handleYes,
        href: raffleHref,
      },
    },
    {
      type: "button",
      button: {
        text: t("confirm_button_no"),
        type: "default",
        onClick: handleNo,
        href: href,
      },
    },
  ];

  return (
    <Fragment>
      <View title={t("confirm_title")} description={t("confirm_desc")}>
        <Navbar />
        <ContentContainer>
          <Title>{t("confirm_title")}</Title>
          <Description>{t("confirm_desc")}</Description>
        </ContentContainer>
        <ActionContainer actions={actions} />
      </View>
    </Fragment>
  );
};

export default QuestionnaireNewConfirmView;
