import { fetchGeo } from "services/geo";
import { useStore } from "stores";

export const updateGeo = async () => {
  const geo = await fetchGeo();
  const { backendUrl, country } = geo;

  // use new locale from geo.json ONLY if there is no locale in the state
  const currentLocale = useStore.getState().locale;
  const locale = currentLocale || geo.locale;

  // alter state
  useStore.setState((state) => ({ ...state, backendUrl, locale, country }));
};
