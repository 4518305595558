import { CountryType, LocaleType } from "models/locale";
import { StateCreator } from "zustand";

export interface GeoSlice {
  backendUrl: string | undefined;
  country: CountryType | undefined;
  locale: LocaleType | undefined;
}

export const createGeoSlice: StateCreator<GeoSlice, [], [], GeoSlice> = () => ({
  backendUrl: undefined,
  country: undefined,
  locale: undefined,
});
