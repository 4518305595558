import {
  getControlQuestionnaireVersion,
  getInitialQuestion,
} from "lib/questionnaire";
import ConfigProvider from "providers/config";
import QuestionnaireProvider from "providers/questionnaire";
import StateProvider from "providers/state";
import { useEffect, useState } from "react";
import { loadCleanQuestionnaire } from "services/questionnaire";
import { v4 } from "uuid";
import ForgotPasswordView from "./view";
import { useLocale } from "hooks/geo";

const ForgotPasswordViewWrapper = () => {
  const locale = useLocale();
  const version = getControlQuestionnaireVersion(locale);
  const [questionId, setQuestionId] = useState<number | undefined>(undefined);

  useEffect(() => {
    const fn = async () => {
      // use bundled json
      const questionnaire = await loadCleanQuestionnaire(
        v4(),
        version,
        locale,
        false
      );
      const questionId = getInitialQuestion(questionnaire).id;
      setQuestionId(questionId);
    };
    fn();
  }, []);

  if (!questionId) {
    return null;
  }

  return (
    <ConfigProvider>
      <StateProvider>
        <QuestionnaireProvider version={version} questionId={questionId}>
          <ForgotPasswordView />
        </QuestionnaireProvider>
      </StateProvider>
    </ConfigProvider>
  );
};

export default ForgotPasswordViewWrapper;
