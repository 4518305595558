import { Fragment, FunctionComponent, useEffect, useState } from "react";
import { ContentSchema, ContentProps } from "../factory";
import {
  StyledBar,
  StyledBarWrapper,
  StyledItem,
  StyledLabel,
  StyledPercentage,
  StyledRow,
  StyledTitle,
} from "./preparing-program.styles";
import { arrayOf } from "lib/array";
import { ValidatorMethod } from "../../validations";
import {
  PreparingProgramContentType,
  PreparingProgramItem,
} from "models/content/preparing-program";

const SKIP_ANIMATIONS = import.meta.env.VITE_PUBLIC_SKIP_ANIMATIONS === "true";

interface PreparingProgramContentProps extends ContentProps {
  content: PreparingProgramContentType;
}

const PreparingProgramContent: FunctionComponent<
  PreparingProgramContentProps
> = ({ content, onSubmit }) => {
  const { preparingProgramItems = [] } = content;
  const emptyArray = arrayOf(0, preparingProgramItems.length);
  const [active, toggleActive] = useState<number | undefined>(undefined); // this points to which bar should be used to advance
  const [percentages, togglePercentages] = useState<number[]>(emptyArray);
  const [loaded, toggleLoaded] = useState(false);

  useEffect(() => {
    const timeouts: NodeJS.Timeout[] = [];

    // initialise
    togglePercentages(emptyArray);

    // initialise widths array
    let overallCounter = 0;
    preparingProgramItems.forEach((item, index) => {
      // create schedule for each bar
      const schedule = generateSchedule(item);

      // schedule bar
      timeouts.push(
        setTimeout(() => {
          // toggle active
          toggleActive(index);

          // iterate through schedule
          let localPercentage = 0;
          let internalCounter = 0;
          for (let i = 0; i < schedule.length; i += 1) {
            timeouts.push(
              setTimeout(() => {
                localPercentage += 1;
                percentages[index] = localPercentage;
                togglePercentages([...percentages]);

                // catch last bar reaching 100%
                if (
                  index === preparingProgramItems.length - 1 &&
                  localPercentage === 100
                ) {
                  timeouts.push(
                    setTimeout(() => {
                      // delegate and trigger nav
                      onSubmit();
                    }, 1500)
                  );
                }
              }, internalCounter)
            );
            internalCounter += schedule[i];
          }
        }, overallCounter)
      );

      // increase counter
      overallCounter += item.duration;
    });

    // initialise
    toggleLoaded(true);

    // to clear out timers
    return () => {
      timeouts.forEach((timeout) => clearTimeout(timeout));
    };
  }, []);

  if (!loaded || active === undefined) {
    return null;
  }

  const skip = () => {
    onSubmit();
  };

  const title = parseTitle(preparingProgramItems[active], percentages[active]);

  return (
    <Fragment>
      <StyledTitle key={title} text={title} />
      {preparingProgramItems.map((item, index) => {
        const percentage = percentages[index];
        return (
          <StyledItem key={`${item.label}`}>
            <StyledBarWrapper>
              <StyledBar
                style={{
                  backgroundColor: item.barColor,
                  width: `${percentage}%`,
                }}
              />
            </StyledBarWrapper>
            <StyledRow
              style={{
                color: item.textColor,
              }}
            >
              <StyledLabel>{item.label}</StyledLabel>
              <StyledPercentage>{percentage}%</StyledPercentage>
            </StyledRow>
          </StyledItem>
        );
      })}
      {SKIP_ANIMATIONS && <div onClick={skip}>SKIP ANIMATION</div>}
    </Fragment>
  );
};

export default PreparingProgramContent;

export const preparingProgramContentValidator: ValidatorMethod = () => {
  // always block, use onSubmit mechanism to trigger nav
  return "block";
};

const parseTitle = (item: PreparingProgramItem, percentage: number) => {
  const { titles } = item;
  const breakpoint = Math.floor(100 / titles.length); // indicates to how many slots we need to divide 100%
  const index = Math.floor(percentage / breakpoint);
  const title = titles[index] || titles[titles.length - 1];
  return title;
};

const generateSchedule = (item: PreparingProgramItem) => {
  const schedule: number[] = []; // this array will have 100 points

  const { duration } = item;
  const durationOfSingleStep = Math.ceil(duration / 100);

  for (let i = 0; i < 100; i += 1) {
    schedule.push(durationOfSingleStep);
  }

  return schedule;
};

export const schema: ContentSchema = {
  Component: PreparingProgramContent,
  validator: preparingProgramContentValidator,
};
