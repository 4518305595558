import Description from "components/description";
import Background from "components/layout/background";
import Block from "components/layout/block";
import Section from "components/layout/section";
import SectionWrapper from "components/layout/section-wrapper";
import View from "components/layout/view";
import Navbar from "components/navigation/navbar";
import Title from "components/title";
import { useTranslations } from "hooks/translations";
import { createLinkTag } from "lib/string";

const linktext = "masterenglish.com";
const melink = "https://www.masterenglish.com/";

const NotFoundView = () => {
  const t = useTranslations();

  const title = t("title_404");
  const p1 = t("text_404");
  const p2 = t("link_404").replace(linktext, createLinkTag(melink, linktext));

  const description = [p1, p2].join("\n");

  return (
    <View title={title} description={p1} footer showLogoInFooter view="light">
      <Navbar />
      <SectionWrapper>
        <Section>
          <Block order={1}>
            <Background
              align="left"
              extension="jpg"
              mobileImageUrl={"home/Error404_Mobile@2x"}
              tabletImageUrl={"home/Error404_Mobile@2x"}
              desktopImageUrl={"home/Error404_Desktop"}
            ></Background>
          </Block>
          <Block order={2}>
            <Title>{title}</Title>
            <Description>{description}</Description>
          </Block>
        </Section>
      </SectionWrapper>
    </View>
  );
};

export default NotFoundView;
