import { MediaExtension } from "lib/media";
import { ContentType } from "./content";
import { OptionModel } from "./option";
import { FollowUpModel } from "./rules";

export interface QuestionModel {
  id: number;
  type: QuestionType;

  // weight in progress calculus, defaults to 1
  weight?: number;

  // schema
  navbar?: boolean;
  tiles?: boolean;
  progress?: boolean;
  dark?: boolean;
  footer?: boolean;

  // new contents
  contents?: ContentType[];

  // footnote text below buttons
  footnote?: string;

  // wallpaper image
  wallpaper?: WallpaperModel;

  // header kind of image
  header?: HeaderModel;

  // scroll
  scroll?: ScrollType;

  // logic stuff
  followUps?: FollowUpModel[];

  // options
  options?: OptionModel[];

  // optional badge
  badge?: string;
}

export interface HeaderModel {
  filename: string;
  extension?: MediaExtension;
  flexible?: boolean;
  alt?: string;
}

export interface WallpaperModel {
  image: string;
  gradient?: boolean;
}

// immediate is only used in the chat
export type ScrollType = "off" | "onPass" | "afterDelay" | "immediate";

export type QuestionType =
  | "LevelQuestion"
  | "InitialLevelView"
  | "TargetLevelView"
  | "QuestionView"
  | "PreparingProgram"
  | "EmbeddedQuestion"
  | "ThankYouView"
  | "ProgramView"
  | "PostPurchase"
  | "SignupView";

export type StaticViewIdMapType = Record<StaticViewEnum, number>;

export const StaticViewIdMap: StaticViewIdMapType = {
  "post-purchase": 1000,
  "thank-you": 1001,
  "verify-email": 1002,
  "update-email": 1003,
  "email-exists": 1004,
  congratulations: 1005,
  "next-step": 1006,
  "start-level-test": 1007,
  "initial-level-test": 1008,
  "reactivate-or-new": 1009,
  reactivate: 1010,
  "new-confirm": 1011,
  "login-with-password": 1012,
};

export type StaticViewEnum =
  | "post-purchase"
  | "thank-you"
  | "verify-email"
  | "update-email"
  | "email-exists"
  | "congratulations"
  | "next-step"
  | "start-level-test"
  | "initial-level-test"
  | "reactivate-or-new"
  | "reactivate"
  | "new-confirm"
  | "login-with-password";
