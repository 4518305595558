import { getControlQuestionnaireVersion } from "lib/questionnaire";
import LinkLoginView from "./view";
import ConfigProvider from "providers/config";
import QuestionnaireProvider from "providers/questionnaire";
import StateProvider from "providers/state";
import { useLocale } from "hooks/geo";

const LinkLoginViewWrapper = () => {
  const locale = useLocale();
  return (
    <ConfigProvider>
      <StateProvider>
        <QuestionnaireProvider
          version={getControlQuestionnaireVersion(locale)}
          questionId={2}
        >
          <LinkLoginView />
        </QuestionnaireProvider>
      </StateProvider>
    </ConfigProvider>
  );
};

export default LinkLoginViewWrapper;
