import { updateLocale } from "actions/locale";
import HelmetWrapper from "components/helmet";
import { usePageLocale } from "hooks/route";
import { Fragment, FunctionComponent, useEffect } from "react";

interface LocaleProviderProps {
  children: any;
}

const LocaleProvider: FunctionComponent<LocaleProviderProps> = ({
  children,
}) => {
  const pageLocale = usePageLocale();

  useEffect(() => {
    updateLocale(pageLocale);
  }, [pageLocale]);

  return (
    <Fragment>
      <HelmetWrapper>
        <html lang={pageLocale} />
      </HelmetWrapper>
      {children}
    </Fragment>
  );
};

export default LocaleProvider;
