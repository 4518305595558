import { TranslationModel } from "models/locale";

const ptTranslations: TranslationModel = {
  link_contact_us: "https://masterenglish.zendesk.com/hc/pt-br",
  link_refund:
    "https://masterenglish.zendesk.com/hc/pt-br/articles/10485682004626--Como-posso-obter-um-reembolso-",

  loading: "Carregando",

  validator_at_least_8: "8 ou mais caracteres",
  validator_invalid_email: "Insira um endereço de e-mail válido",
  validator_required: "Este campo é obrigatório",
  validator_phone: "Digite um número válido com o prefixo",

  old_os_text1:
    "O aplicativo Master English suporta os seguintes sistemas operacionais:",
  old_os_text2:
    "iOS: versão 14.0 ou posterior\nAndroid: versão 11.0 ou posterior",
  old_os_text3:
    "Por favor, atualize seu sistema operacional antes de continuar.",
  old_os_title: "Seu sistema operacional não é compatível",
  old_os_button: "Aceitar",

  footer_menu_help: "Ajuda",
  footer_menu_product: "Produto",
  footer_menu_social: "Redes Sociais",
  footer_contact_us: "Contate-nos",
  footer_main_title: "Programa Master English",
  footer_privacy_policy: "Política de Privacidade",
  footer_terms: "Termos de Uso",
  footer_manage_plan: "Gerencie sua assinatura",
  footer_refund: "Política de reembolso",
  label_email: "E-mail",
  label_firstname: "Nome",
  label_password: "Senha",

  title_404: "Erro 404",
  text_404: "Não foi possível encontrar a página.",
  link_404: "Tente ir para masterenglish.com.",

  terms_of_use: "Termos de Uso",

  account_exists_body:
    "Se você esqueceu sua senha, insira seu e-mail e clique em Continuar. Nós enviaremos um link para você iniciar sessão.",

  account_exists_button: "Continuar",
  account_exists_title: "Esta conta já existe",
  calculating: "Calculando",
  check_email_button1: "Sim",
  check_email_button2: "Não, quero modificar",
  check_email_p1: "Você precisará dele para iniciar sessão no seu programa.",
  check_email_title: "Está correto?",
  congratulations_button1: "Estou mais do que pronto!",
  congratulations_button2: "Estou pronto!",
  congratulations_text:
    "Acreditamos que você tomou uma excelente decisão ao querer investir no seu aprendizado de inglês.<br /><br />Não é apenas sobre aprender um idioma, é investir em você e no seu futuro.<br /><br />Temos tudo pronto para começar o seu aprendizado e levar você até um nível avançado de inglês.<br /><br />Você está pronto para começar?",
  congratulations_title: "Parabéns!",

  dayselection_done: "Pronto! Agora você pode continuar.",
  dayselection_in_progres: "Continue após selecionar ##DAYS## dias.",
  email_error_body: "Endereço de e-mail #EMAIL# não encontrado.",
  email_error_button1: "Tente novamente",
  email_error_button2: "Criar uma conta",
  email_error_title: "Não encontrado!",
  email_exists_button1: "Tente novamente",
  email_exists_p1:
    "O endereço de e-mail #EMAIL# já está reservado para outra conta.",
  email_exists_title: "Este e-mail já existe",
  follow_steps_p3: "Faça login com #EMAIL# e a senha que você acabou de criar.",
  follow_steps_p4: "Complete o teste de nível inicial.",
  follow_steps_title: "Siga estes passos",
  footer_fb_alt: "Link do Facebook",
  footer_ig_alt: "Link do Instagram",
  hint_agree_on_terms_and_privacy:
    "Ao continuar, você aceita nossos #LINK_TOS# e #PRIVACY_LINK#.",
  level_test_button: "Próximo",
  level_test_text:
    "Para garantir uma conexão de internet e uso do microfone otimizados, você completará seu teste de nível inicial por meio do nosso aplicativo móvel.<br /><br />Após completá-lo, você obterá seu programa personalizado de inglês.",
  level_test_title: "Teste de nível inicial",
  link_sent_body:
    "Enviamos um link para iniciar sessão para #EMAIL#. Clique no link que aparecerá no seu e-mail.",
  link_sent_title: "Link enviado",
  login_body:
    "Insira seu e-mail e clique em Continuar. Nós enviaremos um link para você iniciar sessão.",
  login_button: "Continuar",
  login_button_page_body: "Se você não tem o app Master English, baixe o app.",
  login_button_page_button: "Iniciar sessão",
  login_button_page_link: "baixe o app",
  login_button_page_title: "Iniciar sessão no app",
  login_link_expired_body:
    "Seu link expirou. Por favor, clique em Continuar para obter um novo link.",
  login_link_expired_button: "Continuar",
  login_link_expired_title: "Link expirado",
  login_title: "Iniciar sessão",
  login_title_forgot: "Esqueceu sua senha?",
  login_divider: "Nāo tenho uma conta",
  login_button_create_account: "Criar conta",
  login_button_start: "Iniciar sessāo",
  login_button_help: "Precisa de ajuda?",

  confirm_title: "Confirmaçāo",
  confirm_desc:
    "Você está prestes a reiniciar seu progresso acumulado no Master English para começar um novo programa. Esta é sua intenção?",
  confirm_button_yes:
    "Sim, quero reiniciar meu progresso e começar um novo programa",
  confirm_button_no: "Não, não quero reiniciar meu progresso",

  handle_subcription_title: "Gerencie sua assinatura",
  handle_subcription_description:
    "Gerencie sua assinatura do programa Master English nesta página.",
  handle_subcription_p1:
    "Faça sua assinatura para acessar o programa Master English. Depois de ativar a assinatura, basta baixar o aplicativo Master English no seu dispositivo móvel iPhone ou Android e iniciar a sessão.",
  handle_subcription_p2:
    'Se você deseja alcançar um nível avançado de inglês com Master English mas ainda não tem seu programa, <a href="#LINK#">comece aqui</a>.',
  handle_subcription_cancel_title: "Como cancelar sua assinatura",
  handle_subcription_cancel_p1:
    "Sua assinatura renova automaticamente até que você cancele.",
  handle_subcription_cancel_p2:
    "Você pode cancelar sua assinatura a qualquer momento seguindo estes passos:",
  handle_subcription_cancel_item1:
    'Abra o <a href="#LINK#">app Master English</a> e acesse o menu à esquerda',
  handle_subcription_cancel_item2:
    'Selecione <span style="font-weight: 600; color: #050505;">Configurações</span>',
  handle_subcription_cancel_item3:
    "Clique em <span style='font-weight: 600; color: #050505;'>Gerenciar assinatura</span>",
  handle_subcription_initial_text1:
    "Se você fez sua assinatura pelo nosso <strong>website:</strong>",
  handle_subcription_initial_item1:
    "Clique no botão <span style='font-weight: 600; color: #050505;'>Gerenciar assinatura</span>",
  handle_subcription_initial_item2:
    "Você será redirecionado para nosso site onde poderá <span style='font-weight: 600; color: #050505;'>cancelar</span> sua assinatura",
  handle_subcription_initial_text2:
    "Se você fez sua assinatura no <strong>app Master English:</strong>",
  handle_subcription_initial_item3:
    "Você será redirecionado para o menu <span style='font-weight: 600; color: #050505;'>Assinaturas</span> em sua <span style='font-weight: 600; color: #050505;'>Apple App Store</span> ou <span style='font-weight: 600; color: #050505;'>Google Play Store</span> onde poderá cancelar sua assinatura",
  handle_subcription_refund:
    "Se você não está satisfeito com a sua compra, entre em contato conosco pelo e-mail <a href='mailto:soporte@masterenglish.com'>soporte@masterenglish.com</a> dentro de 30 dias da cobrança de sua assinatura, e nós emitiremos um reembolso.",
  handle_subcription_help_title: "Precisa de ajuda?",
  handle_subcription_help_text:
    "Se você tiver dúvidas ou precisar de ajuda para cancelar sua assinatura, fale conosco pelo e-mail <a href='mailto:soporte@masterenglish.com'>soporte@masterenglish.com</a> e ficaremos felizes em ajudar. Responderemos à sua mensagem dentro de 2 dias.",

  reactivate_title: "Reativar seu programa de inglês",
  reactivate_objectives:
    "<span style='color: white; font-weight: 600;'>Objetivos:</span>",
  reactivate_objective:
    "Alcançar <span style='color: #007AFF;'>um nível avançado</span> de inglês",
  reactivate_text:
    "Uma vez reativado seu programa, você poderá ver no aplicativo quantas semanas de prática faltam para alcançar o nível avançado.",
  reactivate_list_title_1: "Áreas de aprendizado de inglês:",
  reactivate_list_title_2: "Como funciona:",
  reactivate_list_text_1: "Speaking, Listening, Reading, Writing e Grammar",
  reactivate_list_text_2:
    "Salvamos seu progresso anterior. Seu programa continuará de onde você parou. Depois que seu programa for reativado, você poderá iniciar sessão no aplicativo Master English com seu aparelho iPhone ou Android. Nossas lições personalizadas permitem que você recupere o passo rapidamente.",
  reactivate_payment_type:
    "Sua assinatura renova automaticamente até que você cancele. Você pode cancelar sua assinatura e seu programa a qualquer momento.\n\nEnviaremos um e-mail 3 dias antes de completar os ${months} meses de duração do seu programa, para que você opte pelo cancelamento da assinatura antes que seja renovado o #MONTHS# º mês, se assim desejar.",
  reactivate_payment_description:
    "Sem compromisso. Cancele a qualquer momento.",
  reactivate_payment_header:
    "Selecione um método de pagamento para reativar seu programa.",
  reactivate_payment_title:
    "Inicie uma assinatura mensal para reativar seu programa",
  reactivate_payment_footer:
    "Você pode cancelar sua assinatura e seu programa a qualquer momento. Para isso, entre em contato conosco pelo e-mail soporte@masterenglish.com.",
  reactivate_reviews: "Nossa avaliação na App Store 4.9/5.",

  reactivate_or_new_title: "Programa pausado",
  reactivate_or_new_desc1: "Seu programa Master English está em pausa.",
  reactivate_or_new_desc2: "O que você gostaria de fazer?",
  reactivate_or_new_button_reactivate:
    "Reativar meu programa existente e continuar de onde parei",
  reactivate_or_new_button_new: "Quero começar um novo programa",

  menu_item1: "Comece hoje",
  menu_item2: "Iniciar sessão",
  menu_item3: "Gerencie seu plano",
  meta_description_front_page:
    "Master English leva você do nível básico ou intermediário para falar inglês com fluência profissional total. Sinta-se seguro em qualquer conversa. Comece hoje!",
  meta_description_licenses_page:
    "O seguinte software de código aberto e bibliotecas de terceiros participaram na criação deste aplicativo.",
  meta_description_privacy_page:
    "Sua privacidade é importante. Nossa Política de Privacidade inclui como coletamos, usamos, divulgamos, transmitimos e armazenamos suas informações pessoais.",
  meta_description_purchase_error_page:
    "Não foi possível processar sua transação. Por favor, tente novamente.",
  meta_description_terms_page:
    "Master English/WordDive S.L. oferece serviços e produtos aos seus usuários, como o programa Master English, de acordo com os presentes Termos de Uso.",
  meta_description_thank_you_page:
    "Obrigado por escolher Master English. Baixe o aplicativo e comece a aprender.",
  months: "meses",
  next_up_button: "Entendido",
  next_up_text:
    "Graças às suas respostas ao nosso questionário, já temos uma ideia clara do seu nível de inglês.<br /><br />A seguir, pediremos que você complete um <span style='color: #007aff;'>Teste de nível inicial</span> para confirmar seu nível de forma precisa.<br /><br />Com base nos seus resultados, faremos os últimos ajustes no seu programa.",
  next_up_title: "Próximo passo",
  page_title_front_page:
    "Fale inglês com fluência profissional | Seu programa online",
  page_title_licenses: "Licenças",
  page_title_privacy: "Política de Privacidade",
  page_title_purchase_error: "Algo deu errado",
  post_purchase_button1: "Verificar e-mail",
  post_purchase_p1: "A seguir, vamos verificar se seu e-mail está correto.",
  post_purchase_title: "Criamos seu programa!",
  purchase_error_button: "Tentar novamente",
  purchase_error_p1: "Não foi possível processar sua transação.",
  purchase_error_title: "Ops! Algo deu errado",
  purchase_loader: "Preparando opção de pagamento",
  section_1_button: "Comece hoje",
  section_1_description_p1_embedded:
    "Master English é um aplicativo desenvolvido para falantes de português que desejam alcançar um nível avançado de inglês.",
  section_1_description_p2_embedded:
    "Após completar o programa Master English você será capaz de participar de conversas em inglês com confiança e se candidatar a vagas de emprego onde o inglês é um requisito.",
  section_1_title:
    "Aprenda <br responsive mobile />a falar inglês <br />fluentemente",
  section_2_description_p1_embedded:
    "Nos especializamos em ajudar adultos falantes de português que desejam alcançar um nível avançado de inglês e se destacar no trabalho.",
  section_2_description_p2_embedded:
    "Não importa se seu nível atual é iniciante, básico, intermediário ou mais elevado, nós te ajudaremos a conquistar um nível avançado da forma mais eficaz possível!",
  section_2_description_p3_embedded:
    "Além disso, você não estará limitado por locais ou horários específicos. Com base no seu nível inicial e suas necessidades, vamos criar um programa personalizado que você pode cumprir de forma independente online com o aplicativo Master English.",
  section_2_title: "Para quem é o Master English?",
  section_3_description_bullet_1: "5 lições de 30 min por semana",
  section_3_description_bullet_1_bold: "Horário 1:",
  section_3_description_bullet_2_bold: "Horário 2:",
  section_3_description_bullet_2_method: "3 lições de 50 min por semana",
  section_3_description_bullet_3: "5 lições de 60 min por semana",
  section_3_description_bullet_3_bold: "Horário intensivo:",
  section_3_description_p1_embedded:
    "Primeiro, pedimos que você responda a um breve questionário para compreender o seu nível inicial de inglês e suas áreas de dificuldade.",
  section_3_description_p2_embedded:
    "Você poderá escolher o horário que melhor se adapte às suas necessidades:",
  section_3_description_p3_embedded:
    "Em seguida, vamos criar um programa personalizado para você e dizer em quanto tempo você pode alcançar um nível avançado de inglês. Geralmente, será entre 6 e 12 meses, dependendo do seu nível inicial e ritmo de aprendizado.",
  section_3_description_p4_embedded:
    "Depois que seu programa for criado, você pode acessar com o aplicativo Master English. Assim que você começar a praticar, conheceremos mais sobre seu ritmo de aprendizado. Dessa forma, podemos otimizar e personalizar suas lições seguintes para te ajudar a progredir no inglês o mais rápido possível.",
  section_3_title: "Como funciona o programa Master English?",
  section_4_description_p1_embedded:
    "Nosso programa foi desenvolvido a partir de um estudo extenso e análise das dificuldades comuns entre falantes de português ao aprender inglês.",
  section_4_description_p2_embedded:
    "Nosso foco de aprendizagem e nossas lições são desenvolvidas por um time premiado de especialistas em língua inglesa. Além disso, todos os nossos professores possuem Mestrado em pedagogia e linguística.",
  section_4_description_p3_embedded:
    "Contamos com um time de falantes nativos de inglês que fornece modelos para os nossos exercícios de conversação e compreensão auditiva.",
  section_4_description_p4_embedded:
    "Nossa tecnologia de aprendizado, patenteada nos Estados Unidos, identifica seu ritmo de aprendizagem e áreas de dificuldade. Com essa informação, personaliza suas lições para garantir que você alcançará resultados permanentes.",
  section_4_title: "Por que o Master English é tão eficaz?",
  section_5_description_p1_embedded:
    "Em qualquer lugar do mundo, o inglês avançado é uma necessidade para quem deseja se destacar no ambiente profissional.",
  section_5_description_p2_embedded:
    "Constantemente, pessoas talentosas perdem oportunidades de progredir na carreira porque seu nível de inglês não é suficiente.",
  section_5_description_p3_embedded:
    "Após observar isso acontecendo por anos, nós decidimos agir. Reunimos um time de 45 especialistas altamente qualificados no ensino de inglês, desenvolvimento de software, experiência do usuário, design e inteligência artificial, e criamos o programa online Master English.",
  section_5_description_p4_embedded:
    "Nosso objetivo é oferecer uma solução eficaz para ajudar pessoas interessadas em alcançar fluência profissional completa em inglês de forma online.",
  section_5_description_p5_embedded:
    "Esperamos que nosso programa ajude você a transformar seus sonhos em realidade.",
  section_5_title: "Por que criamos o Master English?",
  section_6_button: "Comece hoje",
  section_6_description_p1_embedded:
    "Provavelmente, você já gastou bastante tempo se preocupando com o inglês, pensando em como melhorar e testando métodos de aprendizado que não atenderam às suas expectativas. É por isso que você está aqui hoje, certo? Bem, não queremos que você perca mais oportunidades por não ter o nível de inglês necessário.",
  section_6_description_p2_embedded:
    "De agora em diante, queremos que você coloque sua energia em melhorar seu inglês sistematicamente a cada semana, para que, depois de completar o programa, tenha sucesso em um mundo cheio de oportunidades!",
  section_6_description_p3_embedded:
    "Comece hoje sua jornada em direção à um nível avançado de inglês com o aplicativo Master English. É uma decisão que você nunca vai se arrepender. Te vejo lá!",
  section_6_title_v28: "Crie seu programa agora",
  subscription_email_link: "suporte@masterenglish.com",
  thank_you_button: "Baixar Master English",
  thank_you_p2: "Clique no botão abaixo para baixar o app Master English.",
  thank_you_p3: "Abra o app e clique em “Iniciar sessão” (veja a imagem).",
  update_email_button1: "Atualizar",
  update_email_p1: "Certifique-se de escrever seu e-mail corretamente.",
  update_email_title: "Atualize seu e-mail",
};

export default ptTranslations;
