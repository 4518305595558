import { Fragment, FunctionComponent, useEffect, useState } from "react";
import { useTranslations } from "hooks/translations";
import {
  PaddleConfigModel,
  PaddleEventModel,
  PaddleEventType,
} from "models/paddle";
import { getPaddle, handleCheckoutPaymentSelectedEvent } from "lib/paddle";
import {
  StyledFrame,
  StyledLoader,
  StyledSpinner,
  StyledText,
} from "./paddle.styles";
import { ProductModel } from "models/product";
import { useQuestionnaire } from "hooks/questionnaire";
import { useQuestionnaireState } from "hooks/state";
import { write } from "lib/storage";
import { PAYMENT_RETRY_URL_KEY } from "models/payment";
import { fetchPaylink } from "services/paylink";
import { getProgramPlan } from "lib/study-schedule";
import { getProgramView, getVersion } from "lib/questionnaire";
import { useConfig } from "hooks/config";
import { useView } from "hooks/view";
import { useDiscount } from "hooks/products";
import { useQuestionLink, useStaticLink, useStudyLanguage } from "hooks/route";
import { useLocale } from "hooks/geo";

interface PaddleFrameProps {
  product: ProductModel;
  onLoad?: () => void;
}

const frameStyle =
  "width:100%; min-width:200px; background-color: transparent; border: none;";

const PaddleFrame: FunctionComponent<PaddleFrameProps> = ({
  product,
  onLoad,
}) => {
  const config = useConfig();
  const locale = useLocale();
  const t = useTranslations();
  const questionnaire = useQuestionnaire();
  const discount = useDiscount();
  const state = useQuestionnaireState();
  const [isLoading, toggleIsLoading] = useState(true);
  const version = getVersion(questionnaire);
  const pv = getProgramView(questionnaire, state);
  const programViewUrl = useQuestionLink(version, pv.id);
  const programPlan = getProgramPlan(questionnaire, state);
  const view = useView();
  const language = useStudyLanguage();
  const returnPath = useStaticLink(version, "post-purchase");

  const initPaddle = (url: string) => {
    // create paddle config
    const paddleConfig: PaddleConfigModel = {
      locale,
      method: "inline",
      override: url,
      frameTarget: "paddleFrame",
      frameStyle,
      loadCallback: () => {
        try {
          // remove our own spinner
          toggleIsLoading(false);

          // delegate to higher level
          if (onLoad) {
            onLoad();
          }
        } catch (_err) {
          // do nothing
        }
      },
    };

    // open paddle iframe
    const Paddle = getPaddle();
    Paddle.Checkout.open(paddleConfig);
  };

  useEffect(() => {
    let spinnerTimeout: NodeJS.Timeout;

    const init = async () => {
      // fetch paylink
      const paylink = await fetchPaylink({
        productId: product.productId,
        returnPath,
        paymentProvider: "paddle",
        language, // note this is the language to study, not the ui language/locale
        plan: getProgramPlan(questionnaire, state),
        couponCode: discount,
      });

      // also, create and store retry path
      write(PAYMENT_RETRY_URL_KEY, programViewUrl);

      // init paddle
      setTimeout(() => {
        initPaddle(paylink.url);
      }, 2500);

      // force remove spinner after 15sec
      spinnerTimeout = setTimeout(() => {
        toggleIsLoading(false);
      }, 15000);
    };

    init();

    // create an interval to watch if we need to cancel the payment
    return () => {
      clearTimeout(spinnerTimeout);
    };
  }, [product.productId]);

  // catches paddle checkout events and can be used to send events
  const delegatePaddleEvent = (data: PaddleEventModel) => {
    switch (data.event) {
      case PaddleEventType.CheckoutPaymentSelected:
        handleCheckoutPaymentSelectedEvent(config, programPlan, product);
        break;
    }
  };

  // attach event monitoring, switch on every product change
  useEffect(() => {
    (window as any).delegatePaddleEvent = delegatePaddleEvent;
  }, [product.productId]);

  return (
    <StyledFrame $isLoading={isLoading} className="paddleFrame" $view={view}>
      {isLoading && (
        <Fragment>
          <StyledSpinner>
            <StyledLoader />
          </StyledSpinner>
          <StyledText>{t("purchase_loader")}</StyledText>
        </Fragment>
      )}
    </StyledFrame>
  );
};

export default PaddleFrame;
