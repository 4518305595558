import { updateCid, updateVid } from "actions/ids";
import { useCid, useVid } from "hooks/ids";
import { read } from "lib/storage";
import { CONFIG_STATE_KEY, ConfigModel } from "models/config";
import { FunctionComponent, useEffect } from "react";
import { useSearchParams } from "react-router";
import { sendCampaignEvent } from "services/event";

interface IdsProviderProps {
  children: any;
}

const IdsProvider: FunctionComponent<IdsProviderProps> = ({ children }) => {
  const [p] = useSearchParams();

  const versionId = useVid();
  const campaignId = useCid();

  useEffect(() => {
    // update cid and vid if needed
    const _cid = p.get("cid") || undefined;
    const _vid = p.get("vid") || undefined;
    if (_cid !== campaignId && _cid !== undefined) {
      updateCid(_cid);
    }
    if (_vid !== versionId && _vid !== undefined) {
      updateVid(_vid);
    }

    // send event
    const uuid = read<ConfigModel>(CONFIG_STATE_KEY)?.uuid || ""; // default to empty string, if no uuid is found
    sendCampaignEvent({ uuid, campaignId, versionId });
  }, []);

  return children;
};

export default IdsProvider;
