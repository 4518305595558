import Description from "components/description";
import Input from "components/form/input";
import ActionContainer, { ActionType } from "components/layout/actions";
import ContentContainer from "components/layout/content";
import View from "components/layout/view";
import Navbar from "components/navigation/navbar";
import Title from "components/title";
import { useLink, useRaffleLink } from "hooks/route";
import { useTranslations } from "hooks/translations";
import { read, resetStorage, write } from "lib/storage";
import {
  emailValidator,
  requiredValidator,
  runValidations,
  ValidatorFn,
} from "lib/validators";
import { QuestionnaireVersion, TARGET_KEY } from "models/questionnaire";
import { SIGNUP_EMAIL_KEY } from "models/user";
import { useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router";
import { forgotPassword } from "services/user";

const LoginFormView = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const t = useTranslations();
  const raffleUrl = useRaffleLink();
  const loginFailureLink = useLink("login-failed");
  const loginSuccessLink = useLink("login-link-sent");

  // check if we have email in the cache or as in query param
  const cachedEmail = read<string>(SIGNUP_EMAIL_KEY);
  const parameterEmail = searchParams.get("label_email");
  const parsedEmail = parameterEmail || cachedEmail || "";
  const [email, setEmail] = useState(parsedEmail);
  const inputRef = useRef("");

  const handleEmailChange = (email: string) => {
    setEmail(email);
    write(SIGNUP_EMAIL_KEY, email);
  };

  const handleSubmit = async () => {
    const target = read<QuestionnaireVersion>(TARGET_KEY);

    // last minute check to avoid sending, if the email is wrong
    if (runValidations(email, validators) !== undefined && inputRef.current) {
      // get the input and force validation using focus+blur combo
      const input = inputRef.current as any;
      input.focus();
      input.blur();
      return;
    }

    try {
      await forgotPassword(email, target);
      navigate(loginSuccessLink);
    } catch (_err) {
      navigate(loginFailureLink);
    }
  };

  const handleRaffle = async () => {
    // clear storage to emulate raffle better
    resetStorage();
    navigate(raffleUrl);
  };

  const validators: ValidatorFn[] = [requiredValidator(t), emailValidator(t)];

  const actions: ActionType[] = [
    {
      type: "button",
      button: {
        text: t("login_button"),
        type: "primary",
        onClick: handleSubmit,
        href: loginSuccessLink,
      },
    },
    {
      type: "divider",
      divider: t("login_divider"),
    },
    {
      type: "button",
      button: {
        text: t("login_button_create_account"),
        type: "default",
        onClick: handleRaffle,
        href: raffleUrl,
      },
    },
  ];

  return (
    <View
      title={t("login_title")}
      description={t("login_body")}
      showLogoInFooter
      cover
    >
      <Navbar links />
      <ContentContainer>
        <Title>{t("login_title")}</Title>
        <Description>{t("login_body")}</Description>
        <Input
          unique
          type="email"
          inputMode="email"
          autoFocus
          value={email}
          enterKeyHint="go"
          validators={validators}
          placeholder={t("label_email")}
          onChange={handleEmailChange}
          onSubmit={handleSubmit}
          autoComplete="username"
          extRef={inputRef}
        />
      </ContentContainer>
      <ActionContainer actions={actions} />
    </View>
  );
};

export default LoginFormView;
