import { useTheme } from "hooks/theme";
import QuestionProvider from "providers/question";
import { FunctionComponent, useEffect, useState } from "react";
import {
  FADE_IN_DELAY,
  FADE_OUT_DELAY,
  PAUSE,
  StyledTransition,
  TransitionState,
} from "./transition.styles";

interface TransitionProps {
  questionId: number;
  children: any;
}

const Transition: FunctionComponent<TransitionProps> = ({
  questionId,
  children,
}) => {
  // keep a proxied version of question id in state
  const [proxiedQuestionId, toggleProxiedQuestionId] = useState(questionId);
  const [state, toggleState] = useState<TransitionState | undefined>(undefined);
  const theme = useTheme();

  // timer pool
  let fadeOutTimer: NodeJS.Timeout,
    fadeInTimer: NodeJS.Timeout,
    pauseTimer: NodeJS.Timeout;

  // toggle proxied question id using useEffect
  useEffect(() => {
    if (theme === "Kosmo") {
      // do not transition for old theme
      return;
    }

    // exit, if the questionId has not changed (which is the case in the first question)
    if (questionId === proxiedQuestionId) {
      return;
    }

    // cancel previous timers, if still scheduled
    clearTimeout(fadeOutTimer);
    clearTimeout(pauseTimer);
    clearTimeout(fadeInTimer);

    // set state to fadeOut
    toggleState("fadeOut");

    // change proxied state after DELAY
    fadeOutTimer = setTimeout(() => {
      toggleProxiedQuestionId(questionId);

      // stay in pause state for a while
      pauseTimer = setTimeout(() => {
        // toggle the animation
        toggleState("fadeIn");

        fadeInTimer = setTimeout(() => {
          // reset state
          toggleState(undefined);
        }, FADE_IN_DELAY);
      }, PAUSE);
    }, FADE_OUT_DELAY);
  }, [questionId]);

  // do not transition for old theme
  if (theme === "Kosmo") {
    return (
      <QuestionProvider questionId={questionId}>{children}</QuestionProvider>
    );
  }

  return (
    <StyledTransition state={state}>
      <QuestionProvider questionId={proxiedQuestionId}>
        {children}
      </QuestionProvider>
    </StyledTransition>
  );
};

export default Transition;
