import { Fragment, FunctionComponent, useEffect } from "react";
import View from "components/layout/view";
import Title from "components/title";
import Description from "components/description";
import { useTranslations } from "hooks/translations";
import ContentContainer from "components/layout/content";
import Tiles from "components/tiles";
import Navbar from "components/navigation/navbar";
import ActionContainer, { ActionType } from "components/layout/actions";
import { sendVirtualQuestionnaireQuestionShownEvent } from "services/event";
import { useConfig } from "hooks/config";
import { getVersion } from "lib/questionnaire";
import { useQuestionnaire } from "hooks/questionnaire";
import { useNavigate } from "react-router";
import { useStaticLink } from "hooks/route";
import { StaticViewIdMap } from "models/question";

const QuestionnaireNextStepView: FunctionComponent = () => {
  const t = useTranslations();
  const config = useConfig();
  const questionnaire = useQuestionnaire();
  const navigate = useNavigate();

  const href = useStaticLink(getVersion(questionnaire), "start-level-test");

  useEffect(() => {
    // send fixed event
    sendVirtualQuestionnaireQuestionShownEvent({
      uuid: config.uuid,
      questionId: StaticViewIdMap["next-step"],
    });
  }, []);

  const handleClick = () => {
    navigate(href);
  };

  const actions: ActionType[] = [
    {
      type: "button",
      button: {
        text: t("next_up_button"),
        type: "primary",
        onClick: handleClick,
        href: href,
      },
    },
  ];
  return (
    <Fragment>
      <View
        view="dark"
        title={t("next_up_title")}
        description={t("next_up_text")}
        cover
      >
        <Tiles />
        <Navbar />
        <ContentContainer>
          <Title>{t("next_up_title")}</Title>
          <Description>{t("next_up_text")}</Description>
        </ContentContainer>
        <ActionContainer actions={actions} />
      </View>
    </Fragment>
  );
};

export default QuestionnaireNextStepView;
