import { lazy, FunctionComponent } from "react";
import { StyledLottieWrapper } from "./lottie.styles";

// lazy load to create a smaller bundle
const Lottie = lazy(() => import("lottie-react"));

// lazy load to create a smaller bundle

interface LottieProps {
  animationData: any;
  loop?: boolean;
}

const LottieC: FunctionComponent<LottieProps> = ({
  animationData,
  loop = false,
}) => {
  return (
    <StyledLottieWrapper>
      <Lottie animationData={animationData} autoplay={true} loop={loop} />
    </StyledLottieWrapper>
  );
};

export default LottieC;
