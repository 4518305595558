import styled from "styled-components";

export const StyledSwitcher = styled.div`
  position: fixed;
  left: 0.5rem;
  bottom: 0.5rem;
  display: flex;
  flex-direction: row;
  background-color: black;
  z-index: 1000;
  gap: 0.5rem;
  border: 3px solid red;
`;

export const StyledLocale = styled.div`
  font-size: 2rem;
  color: white;
  padding: 0.5rem 1rem;
  cursor: pointer;
  text-transform: uppercase;
`;
